import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles(() => ({
    roundedAccordion: css({
      boxShadow: '0px 16px 48px rgba(73, 92, 136, 0.15)',
      '&:before': { display: 'none' },
      '&.MuiPaper-root': {
        borderRadius: '24px',
        marginBottom: '16px',
      },
      '& .MuiAccordion-root': {
        backgroundColor: '#ECEFF6',
        borderRadius: '24px',
      },
    }),
    summaryContainer: css({
      display: 'flex',
      alignItems: 'center',
    }),
    iconContainer: css({
      '& svg': {
        width: '72px',
        height: '72px',
      },
    }),
    roundedAccordionSummary: css({
      pointerEvents: 'none',
      borderRadius: '24px 24px 0px 0px',
      height: '104px',
    }),
    roundedAccordionDetails: css({
      boxShadow: '0px 16px 48px rgba(73, 92, 136, 0.15)',
      padding: '16px 36px',
      backgroundColor: '#EEF7EA',
      borderRadius: '0px 0px 24px 24px',
      minHeight: '0px',
    }),
    offerContainer: css({
      display: 'flex',
      alignItems: 'center',
    }),
    packageText: css({
      color: '#151617',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '32px',
    }),
    priceText: css({
      '& p': {
        color: '#52AE30',
        fontSize: '14px',
        margin: 0,
        '& strong': {
          fontSize: '18px',
        },
      },
      '& i': {
        color: '#52AE30',
        fontWeight: 700,
        marginTop: '-6px',
        fontSize: '14px',
        fontStyle: 'normal',
      },
      color: 'rgba(125, 130, 139, 1)',
      fontSize: '14px',
      lineHeight: '24px',
    }),
    stepsDescriptionText: css({
      margin: '6px 0px',
      paddingLeft: '8px',
      color: '#151617',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      textAlign: 'left',
    }),
    packageModalButton: css({
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    }),
    packageModalButtonText: css({
      cursor: 'pointer',
      color: '#52AE30',
      fontSize: '14px',
      fontWeight: 700,
      textDecoration: 'underline',
    }),
  }));

export default useStyles;
