import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  setProcessId,
  setUtmMedium,
  startSessionAsync,
  getStepsByProcessAsync,
} from '../../providers/application/application.actions';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../providers/application/application.provider';

const StartSession = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { utmMedium, adUtmSources } = useApplicationState();
  const dispatch = useApplicationDispatch();
  const utmContentProcesses = {
    dkLink: 1,
    ppLink: 2,
    caLink: 3,
  };

  const onSessionSuccess = (
    activeStep,
    steps,
    requestCannotContinue,
    onlinePaymentAccountProcessId,
  ) => {
    const queryParams = new URLSearchParams(location.search);
    const utmMediumParam = queryParams.get('utm_medium');
    const utmSourceParam = queryParams.get('utm_source');
    const utmCampaign = queryParams.get('utm_campaign');
    const utmContent = queryParams.get('utm_content');

    if ((utmMediumParam === 'pyramids' || utmCampaign === 'referrer') && !utmMedium) {
      dispatch(setUtmMedium({ utmMedium: utmMediumParam }));
    }

    if (utmContentProcesses[utmContent] && activeStep === steps[0].nameEN) {
      dispatch(setProcessId(utmContentProcesses[utmContent], false));
      getStepsByProcessAsync(utmContentProcesses[utmContent], dispatch);
    }

    if (
      adUtmSources.indexOf(utmSourceParam) === -1 &&
      activeStep === steps[0].nameEN &&
      !utmContentProcesses[utmContent]
    ) {
      dispatch(setProcessId(onlinePaymentAccountProcessId, !requestCannotContinue));
    }

    navigate('/Request');
  };

  useEffect(() => {
    startSessionAsync(location.search, dispatch, onSessionSuccess);
  }, []);

  return <div />;
};

export default StartSession;
