import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useStyles from './header.styles';
import DskLogo from '../../assets/images/dsk_logo.png';
import Steps from '../steps/steps.component';
import { useApplicationState } from '../../providers/application/application.provider';
import PhoneIcon from '../../assets/images/phone_icon.svg';
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */

function NavMenu({ currentRef }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    activeStep,
    nomenclatures: { callCenterPhoneNumber },
    showEntryStep,
  } = useApplicationState();

  const handleLogoClick = () => {
    if (activeStep) {
      window.location.reload(true);
    } else {
      navigate(`/`);
    }
  };

  return (
    <Container className={`header-container ${classes.headerContainer}`}>
      <Container className={`mui-fixed ${classes.headerContentContainer}`} maxWidth='xl'>
        <Grid
          container
          className={` ${showEntryStep ? classes.hideLogoContainer : classes.logoContainer}`}
        >
          <Grid item xs={12} sm={3} className={classes.dskLogo}>
            <img
              src={DskLogo}
              className={classes.dskLogoHeader}
              alt='logo'
              onClick={handleLogoClick}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Steps currentRef={currentRef} />
          </Grid>
          <Grid item sm={3} className={classes.dskCallCenter}>
            <Box
              component='a'
              href={`tel: ${callCenterPhoneNumber}`}
              className={classes.callCenterContainer}
            >
              <img src={PhoneIcon} alt='' className={classes.callCenterIcon} />
              <Box component='span' className={classes.callCenterContent}>
                {`${t('footer.call-center')} ${callCenterPhoneNumber}`}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default NavMenu;
