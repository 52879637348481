import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    formContainer: css({
      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: '400px',
        margin: 'auto',
      },
      '& > div': {
        maxWidth: '400px',
      },
      '& label': {
        marginRight: 0,
      },
    }),
    childrenContainer: css({
      marginTop: '28px',
      '&:last-child': {
        marginTop: '16px',
      },
    }),
    companyNameInput: css({
      '& input': {
        textTransform: 'uppercase',
        '&::placeholder': {
          textTransform: 'none',
        },
      },
    }),
    employeeIdentifierWrapper: css({
      marginTop: '-232px',
      marginBottom: '80px',
      [theme.breakpoints.down('md')]: {
        marginTop: '-230px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '-220px',
      },
    }),
  }));

export default useStyles;
