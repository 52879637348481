import React, { useEffect, useImperativeHandle } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation, Trans } from 'react-i18next';
import useStyles from './request.styles';
import { bytesToMegabytes } from '../../utils/common.utils';
import { goToPreviousStep, setProcessId } from '../../providers/application/application.actions';
import ClientDataStep from '../../components/request/client-data-step/client-data-step.component';
import IdentificationStep from '../../components/request/identification-step/identification-step.component';
import GeneralTermsStep from '../../components/request/general-terms-step/general-terms-step.component';
import AdditionalDataStep from '../../components/request/additional-data-step/additional-data-step.component';
import DocumentsDataStep from '../../components/request/documents-data-step/documents-data-step.component';
import RequestSuccess from '../../components/request/request-success/request-success.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import RequestInfo from '../../components/request/request-info/request-info.component';
import ReloadRequiredPrompt from '../../components/reload-required-prompt/reload-required-prompt.component';
import EntryStep from '../../components/request/entry-step/entry-step.component';
import PackagesDataStep from '../../components/request/packages-data-step/packages-data-step.component';
import DebitCardStep from '../../components/request/debit-card-step/debit-card-step.component';
import DocumentsUploadStep from '../../components/request/documents-upload-step/documents-upload-step.component';
import OcrDataPreview from '../../components/request/ocr-data-preview/ocr-data-preview.component';

function getStepContent(steps, step, formRefs, backRefs) {
  const stepIndex = steps.map((item) => item.nameEN).indexOf(step);

  switch (step) {
    case 'ClientData':
      return <ClientDataStep formRef={formRefs[stepIndex]} referrerUrl={document.referrer} />;
    case 'GeneralTerms':
      return <GeneralTermsStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'DocumentsUpload':
      return <DocumentsUploadStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'Identification':
      return <IdentificationStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'AdditionalData':
      return <AdditionalDataStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'DebitCard':
      return <DebitCardStep formRef={formRefs[stepIndex]} backRef={backRefs[stepIndex]} />;
    case 'DocumentsData':
      return <DocumentsDataStep formRef={formRefs[stepIndex]} />;
    case 'Packages':
      return <PackagesDataStep formRef={formRefs[stepIndex]} />;
    default:
      return '';
  }
}

function Request({ currentRef }) {
  const dispatch = useApplicationDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const {
    activeStep,
    loading,
    requestCannotContinue,
    confirmationEmailPending,
    resumeEmailPending,
    isSessionStarted,
    loadingDocuments,
    showDocumentsVerificationData,
    processId,
    showEntryStep,
    packageId,
    nomenclatures: { steps, assistedPaymentAccountProcessId, onlinePaymentAccountProcessId },
    uploadedActFiles,
    uploadedProtocolFiles,
    showOcrDataScreen,
    isOcrDataConfirmed,
    allowedOnlyImages,
    pdfMimeType,
    incorporationProtocolValidName,
    incorporationActValidName,
  } = useApplicationState();

  const getStepNameByProcess = (stepName) => {
    switch (processId) {
      case assistedPaymentAccountProcessId:
        return stepName ? `/${stepName}_Branch` : 'Branch';
      case onlinePaymentAccountProcessId:
        return stepName ? `/${stepName}_Online` : 'Online';
      default:
        return stepName ? `/${stepName}_CapitalAcc` : 'CapitalAcc';
    }
  };

  const progressBarSteps = steps.filter((step) => step.partOfStepsFlow);
  const currentStep = progressBarSteps.find(
    (step) => step.nameEN === (activeStep || steps[0].nameEN),
  );
  const formRefs = progressBarSteps.map(() => React.createRef());
  const backRefs = progressBarSteps.map(() => React.createRef());
  const currentStepIndex = progressBarSteps
    ? progressBarSteps.map((item) => item.nameEN).indexOf(currentStep && currentStep.nameEN)
    : 0;
  const isFinalStepForUser =
    progressBarSteps.length && activeStep === progressBarSteps[progressBarSteps.length - 1].nameEN;

  useImperativeHandle(currentRef, () => ({
    handleBack: () => {
      const backRef = backRefs[currentStepIndex];
      if (backRef && backRef.current) {
        dispatch(goToPreviousStep(backRef.current.getValues()));
      } else if (steps.length && currentStep.nameEN === progressBarSteps[0].nameEN) {
        dispatch(setProcessId(processId, true));
      } else {
        dispatch(goToPreviousStep());
      }
    },
  }));

  useEffect(() => {
    // Note: Scroll to top when change active step or info step,
    // showDocumentsVerificationData opens more info on DocumentsData page
    if (!showDocumentsVerificationData) {
      window.scrollTo(0, 0);
    }

    // Note: Triggers page change for hotjar heatmaps
    if (isSessionStarted && typeof window !== 'undefined' && typeof window.hj === 'function') {
      let pageName;
      if (confirmationEmailPending) {
        pageName = '/ConfirmationEmailPending';
      } else if (resumeEmailPending) {
        pageName = '/ResumeEmailPending';
      } else if (requestCannotContinue) {
        pageName = '/RequestCannotContinue';
      } else if (showDocumentsVerificationData) {
        pageName = '/DocumentsData_Signing';
      } else if (showEntryStep) {
        pageName = '/EntryStep';
      } else if (showOcrDataScreen) {
        pageName = '/OcrDataPreview';
      } else if (!activeStep || activeStep === 'ClientData') {
        pageName = getStepNameByProcess('ClientData');
      } else if (!loadingDocuments && activeStep !== steps[steps.length - 1].nameEN) {
        pageName = `/${activeStep}`;
      }

      if (pageName) {
        window.hj('stateChange', pageName);
      }
    }
  }, [
    activeStep,
    requestCannotContinue,
    confirmationEmailPending,
    resumeEmailPending,
    showDocumentsVerificationData,
    processId,
    showEntryStep,
    isSessionStarted,
    loadingDocuments,
    showOcrDataScreen,
  ]);

  const handleNext = () => {
    const formRef = formRefs[currentStepIndex];
    if (formRef && formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  if (!isSessionStarted) {
    return <Navigate to={`/${location.search}`} replace />;
  }

  if (activeStep && activeStep === steps[steps.length - 1].nameEN) {
    return (
      <div className={classes.root}>
        <RequestSuccess />
      </div>
    );
  }

  if (requestCannotContinue || confirmationEmailPending || resumeEmailPending) {
    return (
      <div className={classes.root}>
        <RequestInfo />
      </div>
    );
  }

  if (showEntryStep) {
    return (
      <div className={classes.root}>
        <EntryStep />
      </div>
    );
  }

  if (showOcrDataScreen) {
    return (
      <div className={classes.root}>
        <OcrDataPreview />
      </div>
    );
  }

  const packageScreenCondition =
    !packageId && processId === assistedPaymentAccountProcessId && activeStep === 'Packages';

  const ocrScreenCondition =
    activeStep === 'DocumentsUpload' &&
    !isOcrDataConfirmed &&
    (uploadedActFiles.length === 0 ||
      uploadedProtocolFiles.length === 0 ||
      !incorporationProtocolValidName ||
      !incorporationActValidName ||
      uploadedActFiles.some((el) => Boolean(el.errorMessage)) ||
      uploadedProtocolFiles.some((el) => Boolean(el.errorMessage)) ||
      Boolean(
        bytesToMegabytes(uploadedActFiles.reduce((acc, cur) => acc + cur.file.size, 0)) > 14,
      ) ||
      Boolean(
        bytesToMegabytes(uploadedProtocolFiles.reduce((acc, cur) => acc + cur.file.size, 0)) > 14,
      ) ||
      (uploadedActFiles.some((item) => allowedOnlyImages.indexOf(item.file.type) !== -1) &&
        uploadedActFiles.some((item) => item.file.type === pdfMimeType)) ||
      (uploadedProtocolFiles.some((item) => allowedOnlyImages.indexOf(item.file.type) !== -1) &&
        uploadedProtocolFiles.some((item) => item.file.type === pdfMimeType)));

  return (
    <div className={classes.root}>
      {processId === assistedPaymentAccountProcessId && currentStep.nameEN === steps[0].nameEN ? (
        <div className={classes.employeeIdentifierPush} />
      ) : (
        ''
      )}
      {!loadingDocuments && (
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 900,
              fontSize: '28px',
              lineHeight: '32px',
              color: '#151617',
              marginBottom: '12px',
            }}
          >
            {currentStep.name}
          </Box>
          {currentStep.description && (activeStep !== 'DocumentsUpload' || !isOcrDataConfirmed) && (
            <Box
              style={{
                alignItems: 'center',
                textAlign: 'center',
                color: '#151617',
                marginBottom: '32px',
                whiteSpace: 'pre-line',
              }}
            >
              <Trans>{currentStep.description}</Trans>
            </Box>
          )}
        </Grid>
      )}
      {getStepContent(steps, currentStep.nameEN, formRefs, backRefs)}
      {!loadingDocuments && (
        <div className={classes.buttonOuterWrapper}>
          <Button
            id={
              !showDocumentsVerificationData && isFinalStepForUser
                ? `btnForward_ShowDocumentsVerificationData_${getStepNameByProcess()}`
                : `btnForward_${getStepNameByProcess(currentStep.nameEN).replace('/', '')}`
            }
            variant='contained'
            color='primary'
            onClick={handleNext}
            className={isFinalStepForUser ? classes.largeButton : classes.button}
            disabled={loading || packageScreenCondition || ocrScreenCondition}
          >
            {showDocumentsVerificationData && isFinalStepForUser
              ? t('general.complete')
              : currentStep.submitBtnLabel}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      )}

      <ReloadRequiredPrompt />
    </div>
  );
}

export default Request;
