import 'form-request-submit-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './translations/i18n';
import './index.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
);
