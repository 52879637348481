import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = (showThanksMessage) =>
  makeStyles((theme) => ({
    contentContainer: css({
      width: '100%',
      flexDirection: 'column',
      textAlign: 'center',
      marginLeft: 0,
      pointerEvents: showThanksMessage ? 'none' : 'initial',
      filter: showThanksMessage ? 'blur(3px)' : 'unset',
      opacity: showThanksMessage ? '0.2' : 'initial',
      [theme.breakpoints.down('sm')]: {
        padding: '0px 8px',
      },
    }),
    requestSuccessTitle: css({
      textAlign: 'center',
      fontWeight: 900,
      fontSize: '28px',
      lineHeight: '32px',
      color: '#52AE30',
    }),
    requestSuccessSubtitle: css({
      fontSize: '18px',
      lineHeight: '26px',
      color: '#151617',
      marginTop: '24px',
      marginBottom: '8px',
    }),
    combineTextContentContainer: css({
      padding: '0px 4px',
      marginTop: '18px',
    }),
    requestSuccessTextLine1: css({
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '26px',
      textAlign: 'justify',
    }),
    requestSuccessTextLine2: css({
      fontSize: '16px',
      lineHeight: '26px',
      marginTop: '24px',
      marginBottom: '16px',
      textAlign: 'justify',
    }),
    greenSection: css({
      display: 'flex',
      flexDirection: 'column',
      background: '#FFFFFF',
      boxShadow: '0px 0px 2px rgba(166, 173, 186, 0.24), 0px 8px 24px rgba(166, 173, 186, 0.24)',
      borderRadius: '40px',
      margin: '8px 0px',
      textAlign: '-webkit-center',
      paddingBottom: '24px',
      paddingTop: '24px !important',
      alignItems: 'center',
    }),
    greenBox: css({
      padding: '0px 56px',
      marginBottom: '24px',
      [theme.breakpoints.between('xs', 'md')]: {
        maxWidth: '248px',
        padding: 0,
      },
      '@media (max-width: 320px)': {
        maxWidth: '220px',
      },
    }),
    greenTitle: css({
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: 700,
      color: '#151617',
      marginBottom: '8px',
    }),
    greenButton: css({
      width: '96px',
      height: '40px',
      fontSize: '14px',
      backgroundColor: '#52AE30 !important',
      color: '#FFFFFF',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: '#74bf1e !important',
        },
      },
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: '#52ae30 !important',
        },
      },
    }),
    ratingSection: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-center',
      padding: '16px 24px',
      position: 'static',
      width: '100%',
      left: '0px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 2px rgba(166, 173, 186, 0.24), 0px 8px 24px rgba(166, 173, 186, 0.24)',
      borderRadius: '27px',
      flex: 'none',
      order: '1',
      flexGrow: '0',
      margin: '16px 0px',
    }),
    ratingIcons: css({
      position: 'static',
      width: '229px',
      height: '33.4px',
      left: '13.5px',
      top: '40px',
      flex: 'none',
      order: '0',
      flexGrow: '0',
      margin: '0px 8px',
      fontSize: '33.4px',
    }),
    ratingIconBox: css({
      marginTop: '16px',
    }),
    businessAppStore: css({
      position: 'relative',
      width: '100%',
      background: '#FFFFFF',
      boxShadow: '0px 0px 2px rgba(166, 173, 186, 0.24), 0px 8px 24px rgba(166, 173, 186, 0.24)',
      borderRadius: '27px',
    }),
    businessAppStoreRows: css({
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
      },
    }),
    playStoreRow: css({
      padding: '16px 0px 24px 24px',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        padding: '16px 0px 16px 16px',
      },
      '@media (max-width: 350px)': {
        padding: '8px 0px 8px 8px',
      },
    }),
    appStoreHeader: css({
      width: 'max-content',
      fontSize: '16px',
      marginBottom: '16px',
      textAlign: 'left',
      lineHeight: '24px',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      '@media (max-width: 350px)': {
        fontSize: '12px',
      },
    }),
    playStoreButtonsContainer: css({
      cursor: 'pointer',
      textAlign: 'left',
    }),
    dskBusinessImageContainer: css({
      display: 'flex',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '110%',
        position: 'absolute',
        right: 0,
        bottom: 0,
      },
    }),
    thanksMessage: css({
      zIndex: 999,
      width: '100%',
      color: '#52AE30',
      textAlign: 'center',
      position: showThanksMessage ? 'absolute' : 'initial',
      top: showThanksMessage ? '50%' : 'initial',
      left: showThanksMessage ? '50%' : 'initial',
      transform: showThanksMessage ? 'translate(-50%, -50%)' : 'initial',
      ' & p': {
        fontSize: '33px',
        fontWeight: 900,
      },
    }),
  }));

export default useStyles;
