import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { useApplicationState } from '../../providers/application/application.provider';
import useStyles from './steps.styles';
import DskLogo from '../../assets/images/dsk_logo.png';
import ProcessDescriptionModal from '../request/process-description-dialog/process-description-dialog.component';
import { ReactComponent as InfoOutlined } from '../../assets/images/info-outlined.svg';

function Steps({ currentRef }) {
  const {
    activeStep,
    requestCannotContinue,
    confirmationEmailPending,
    resumeEmailPending,
    showDocumentsVerificationData,
    loadingDocuments,
    loading,
    generateSmsVerificationCodeLoading,
    showEntryStep,
    emailConfirmed,
    nomenclatures: { steps },
    showOcrDataScreen,
  } = useApplicationState();

  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const progressBarSteps = steps.filter((step) => step.partOfStepsFlow);
  const stepsCount = progressBarSteps.length;
  const currentStepNameEN = activeStep || (stepsCount && progressBarSteps[0].nameEN);
  const currentStepIndex = progressBarSteps.map((item) => item.nameEN).indexOf(currentStepNameEN);
  const currentStepNumber = currentStepIndex >= 0 ? currentStepIndex + 1 : 1;

  const handleBack = () => {
    if (!loading && !generateSmsVerificationCodeLoading) {
      currentRef.current.handleBack();
    }
  };

  const [showStepsDescriptionDialog, setShowStepsDescriptionDialog] = React.useState(false);
  const handleStepsDescriptionDialogClose = () => {
    setShowStepsDescriptionDialog(false);
  };

  const handleStepsDescriptionPreview = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowStepsDescriptionDialog(true);
  };

  const handleLogoClick = () => {
    if (activeStep) {
      window.location.reload(true);
    } else {
      navigate(`/`);
    }
  };

  const showStepsComponent =
    requestCannotContinue ||
    confirmationEmailPending ||
    resumeEmailPending ||
    showEntryStep ||
    showOcrDataScreen ||
    !progressBarSteps.find((step) => step.nameEN === currentStepNameEN);

  const showOnlyLogoMobile = mobile && showStepsComponent && !showEntryStep;

  if (showOnlyLogoMobile) {
    return (
      <div className={classes.logoContainer}>
        <Grid item xs={12} onClick={handleLogoClick}>
          <img src={DskLogo} style={{ width: '160px' }} alt='logo' />
        </Grid>
      </div>
    );
  }

  if (showStepsComponent) {
    return <div />;
  }

  return (
    <div className={classes.stepsWrapper}>
      <div className={classes.stepsContainer}>
        <div className={classes.stepperButtonContainer}>
          {!(
            showEntryStep ||
            (stepsCount && currentStepNameEN === progressBarSteps[0].nameEN && emailConfirmed)
          ) &&
            !loadingDocuments &&
            !showDocumentsVerificationData && (
              <IconButton
                className={
                  !loading && !generateSmsVerificationCodeLoading
                    ? classes.stepperButton
                    : classes.backButtonDisabled
                }
                onClick={handleBack}
              >
                <ArrowBackIosRoundedIcon className={classes.arrowBackButton} />
              </IconButton>
            )}
        </div>
        <div className={classes.stepperContainer}>
          <Grid item xs={12} className={classes.dskLogoMobile} onClick={handleLogoClick}>
            <img src={DskLogo} style={{ width: '160px' }} alt='logo' />
          </Grid>
          <div className={`${classes.progressBar} ${classes.progressBarDimensions}`}>
            <div className={`${classes.progressBarBackground} ${classes.progressBarDimensions}`}>
              {currentStepNumber} / {stepsCount}
            </div>
            <div
              className={classes.progressBarForegroundContainer}
              style={{ width: `${(currentStepNumber * 100) / stepsCount}%` }}
            >
              <div className={`${classes.progressBarForeground} ${classes.progressBarDimensions}`}>
                {currentStepNumber} / {stepsCount}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.stepperButtonContainer}>
          <IconButton className={classes.stepperButton} onClick={handleStepsDescriptionPreview}>
            <InfoOutlined fontSize='inherit' className={`${classes.infoIcon}`} />
          </IconButton>
        </div>
      </div>

      <ProcessDescriptionModal
        open={showStepsDescriptionDialog}
        handleClose={handleStepsDescriptionDialogClose}
      />
    </div>
  );
}

export default Steps;
