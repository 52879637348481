// eslint-disable-next-line import/prefer-default-export
import { useTheme } from '@mui/material';

export function filterOptions(options, { inputValue, getOptionLabel }) {
  const inputStrings = inputValue
    .trim()
    .toLowerCase()
    .split(' ')
    .filter((s) => s);

  return inputStrings.length > 0
    ? options.filter((option) => {
        const candidate = getOptionLabel(option).toLowerCase();
        return inputStrings.every((s) => candidate.indexOf(s) > -1);
      })
    : options;
}

export const makeStyles = (fn) => {
  return fn(useTheme());
};

export const bytesToKilobytes = (bytes) => {
  return bytes / 1024;
};

export const bytesToMegabytes = (bytes) => {
  return bytesToKilobytes(bytes) / 1024;
};

export const compressImage = (file, maxWidth, quality) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const scale = maxWidth / image.width;
        canvas.width = maxWidth;
        canvas.height = image.height * scale;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => resolve(blob), file.type, quality);
      };
    };
    reader.readAsDataURL(file);
  });
};
