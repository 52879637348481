import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import checkIcon from '../../../assets/images/check_icon.svg';
import logoBckg from '../../../assets/images/logo_bckg.svg';
import useStyles from './offer-dialog.styles';
import { useApplicationState } from '../../../providers/application/application.provider';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { ReactComponent as InfoOutlined } from '../../../assets/images/info-outlined.svg';

const OfferModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    processId,
    nomenclatures: {
      businessClientsTariffUrl,
      capitalAccountProcessId,
      onlinePaymentAccountProcessId,
      showPromoOffer,
    },
  } = useApplicationState();

  return (
    <Grid>
      <Dialog
        onClose={handleClose}
        open={open}
        className={mobileView ? classes.mobileDialog : classes.dialog}
        fullScreen={mobileView}
      >
        <DialogContent className={classes.dialogContent}>
          <Fab size='medium' className={classes.closeButton} onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </Fab>
          <Grid container>
            <Grid item xs={12} className={classes.offerHeader}>
              {showPromoOffer && processId !== capitalAccountProcessId ? (
                <span>{t('client-data-step.offer-promo-campaign-price')}</span>
              ) : (
                <span>{t('client-data-step.offer-card-title')}</span>
              )}
            </Grid>
            <Grid item xs={12} className={classes.offerText}>
              {processId === capitalAccountProcessId ? (
                <span>{t('offer-dialog-subtitle-capital-account')}</span>
              ) : (
                <div>
                  {showPromoOffer && processId !== capitalAccountProcessId ? (
                    <span>{t('offer-dialog-campaign-subtitle')}</span>
                  ) : (
                    <>
                      <span>{t('offer-dialog.subtitle-first-part')}</span>
                      <span className={classes.offerAmount}>{t('offer-dialog.amount')}</span>
                      <span>{t('offer-dialog.subtitle-second-part')}</span>
                    </>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid>
            <div style={{ marginTop: '24px' }} className={classes.rowCheck}>
              <Grid className={classes.checkIconWrapper}>
                <img src={checkIcon} alt='check icon' />
              </Grid>
              <Grid className={classes.checkList}>
                <div>
                  {processId === capitalAccountProcessId
                    ? t('offer-dialog.first-text-capital-account')
                    : t('offer-dialog.first-text')}
                </div>
              </Grid>
            </div>
            <div className={classes.rowCheck}>
              <Grid className={classes.checkIconWrapper}>
                <img src={checkIcon} alt='check icon' />
              </Grid>
              <Grid className={classes.checkList}>
                <div>
                  {processId === capitalAccountProcessId
                    ? t('offer-dialog.second-text-capital-account')
                    : t('offer-dialog.second-text')}
                </div>
              </Grid>
            </div>
            <div className={classes.rowCheck}>
              <Grid className={classes.checkIconWrapper}>
                <img src={checkIcon} alt='check icon' />
              </Grid>
              <Grid className={classes.checkList}>
                <div>
                  {processId === capitalAccountProcessId
                    ? t('offer-dialog.third-text-capital-account')
                    : t('offer-dialog.third-text')}
                </div>
              </Grid>
            </div>
          </Grid>
          {showPromoOffer && processId !== capitalAccountProcessId && (
            <div className={classes.campaignSection}>
              <div style={{ marginRight: '16px' }}>
                <InfoOutlined className={classes.infoIcon} />
              </div>
              <div>
                <Typography className={classes.campaignText}>
                  {t('offer-dialog-campaign-text')}
                </Typography>
              </div>
            </div>
          )}
          <Box className={classes.border} />
          <Box>
            <Grid>
              <img src={logoBckg} alt='' className={classes.pageLogo} />
              <Trans>
                <div className={classes.noteText}>
                  {processId === capitalAccountProcessId
                    ? t('offer-dialog.note-text-capital-account')
                    : t('offer-dialog.note-text')}
                  {processId === onlinePaymentAccountProcessId && (
                    <a
                      href={businessClientsTariffUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.noteTextLink}
                    >
                      {t('offer-dialog.link')}
                    </a>
                  )}
                </div>
              </Trans>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
export default OfferModal;
