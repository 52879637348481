import React from 'react';
import { useTheme, useMediaQuery, Grid, Fab, Dialog, DialogContent, Divider } from '@mui/material';
import { Trans } from 'react-i18next';
import { ReactComponent as Check } from '../../assets/images/check_icon.svg';
import useStyles from './package-dialog.styles';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

const PackageDialog = ({ selectedPackage, open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formattedTerms = selectedPackage && selectedPackage.terms.split('<br/>');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      className={classes.packageDialog}
    >
      <DialogContent className={classes.dialogContent}>
        <Fab size='medium' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </Fab>
        <Grid container className={classes.gridContent}>
          <Grid item xs={12} style={{ color: '#104E88', fontWeight: 700, lineHeight: '24px' }}>
            {selectedPackage && selectedPackage.packageHeader}
          </Grid>
          <Grid item xs={12} className={classes.packageTitle}>
            {`"${selectedPackage && selectedPackage.name}"`}
          </Grid>
          {selectedPackage && selectedPackage.showPriceInTermsDialog && (
            <Grid item xs={12} className={classes.packagePrice}>
              <Trans>{selectedPackage && selectedPackage.price}</Trans>
            </Grid>
          )}
          <Grid item xs={12}>
            {selectedPackage &&
              formattedTerms.map((term, i) => (
                <div key={`${i + term}`} className={classes.packageTermsContainer}>
                  <div>
                    <Check style={{ width: '21px', height: '24px' }} />
                  </div>
                  <div>
                    <Trans components={{ ul: <ul />, li: <li /> }}>{term}</Trans>
                  </div>
                </div>
              ))}
          </Grid>
          <Grid item xs={12} className={classes.packageConsentText}>
            <Trans>{selectedPackage && selectedPackage.infoText}</Trans>
          </Grid>
          <Divider sx={{ width: '100%', marginTop: '24px', marginBottom: '24px' }} />
          <Grid item xs={12} className={classes.packageAdditionalInfo}>
            <Trans>{selectedPackage && selectedPackage.infoTextNote}</Trans>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PackageDialog;
