import React, { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Trans } from 'react-i18next';
import useStyles from './info.styles';
import InfoDialog from '../info-dialog/info-dialog.component';
import InfoIcon from '../../assets/images/info_icon.svg';
import { ReactComponent as InfoOutlined } from '../../assets/images/info-outlined.svg';

const Info = ({ title, content, smallIcon, topAlign }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(hover: hover) and (pointer: fine)');

  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const infoDialogOpen = () => {
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };

  const infoIcon = (
    <InfoOutlined
      fontSize='inherit'
      className={`${classes.infoIcon} ${smallIcon ? classes.small : ''}`}
      onClick={() => !isDesktop && infoDialogOpen()}
    />
  );

  if (isDesktop) {
    return (
      <Tooltip
        title={
          <>
            {title && (
              <span className={classes.desktopTitle}>
                <Trans>{title}</Trans>
              </span>
            )}
            <Trans>{content}</Trans>
          </>
        }
        arrow
        placement='right'
      >
        {infoIcon}
      </Tooltip>
    );
  }

  return (
    <>
      {infoIcon}
      <InfoDialog
        open={showInfoDialog}
        title={<Trans>{title}</Trans>}
        handleClose={infoDialogClose}
        topAlign={topAlign}
        icon={InfoIcon}
      >
        {content}
      </InfoDialog>
    </>
  );
};

export default Info;
