import { css } from '@emotion/css';
import { makeStyles } from '../../utils/common.utils';

const useStyles = (successScreen) =>
  makeStyles((theme) => ({
    paper: css({
      borderRadius: '32px',
      marginBottom: theme.spacing(3),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      boxShadow: 'none',
      padding: '64px 0',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '24px',
        padding: '24px 0',
      },
    }),
    sidePanelHeader: css({
      fontSize: '36px',
      lineHeight: '40px',
      fontWeight: '700',
      marginBottom: '10px',
      [theme.breakpoints.between('md', 'xl')]: {
        fontSize: '18px',
        lineHeight: '26px',
        marginBottom: '4px',
      },
    }),
    transparentBackground: css({
      [theme.breakpoints.down('sm')]: {
        background: 'transparent',
        boxShadow: 'none',
      },
    }),
    entryStepContentContainer: css({
      [theme.breakpoints.down('md')]: {
        marginTop: '24px !important',
      },
    }),
    contentContainer: css({
      marginTop: '124px',
      paddingLeft: '96px',
      paddingRight: '96px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '16px',
        paddingRight: '0px !important',
      },
    }),
    dataContainer: css({
      paddingTop: '0px !important',
      marginTop: '0px !important',
      marginBottom: '32px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '8px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '40px !important',
      },
    }),
    mainContainer: css({
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      minHeight: '100vh',
      paddingLeft: '0px',
      paddingRight: '0px',
      position: 'relative',
    }),
    contentBox: css({
      padding: '0px 96px',
      [theme.breakpoints.down('sm')]: {
        padding: successScreen ? '0px 8px' : '0px 16px',
      },
    }),
    loaderContainer: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
    }),
    loader: css({
      width: '50px !important',
      height: '50px !important',
    }),
    displayNone: css({
      display: 'none',
    }),
  }));
export default useStyles;
