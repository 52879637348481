export async function fetchHandleErrors(response) {
  if (!response.ok) {
    let error = '';

    try {
      const json = await response.json();
      error = json.errorMessage || response.status;
    } catch (err) {
      error = response.status;
    }

    throw error;
  }

  return response.json();
}

export function fetchPostJSON(url, data = {}) {
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
  };

  return fetch(url, options).then(fetchHandleErrors);
}

export function fetchPostFormData(url, data = {}) {
  const options = {
    method: 'POST',
    body: data,
    cache: 'no-store',
  };

  return fetch(url, options).then(fetchHandleErrors);
}

export function fetchGetJSON(url) {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-store',
  };

  return fetch(url, options).then(fetchHandleErrors);
}
