import i18n from 'i18next';
import ApplicationActionTypes from './application.types';
import { fetchGetJSON, fetchPostJSON, fetchPostFormData } from '../../utils/fetch.utils';

export const reloadRequiredFailure = (data) => ({
  type: ApplicationActionTypes.RELOAD_REQUIRED_FAILURE,
  payload: data,
});

export const generalServerFailure = (data) => ({
  type: ApplicationActionTypes.GENERAL_SERVER_FAILURE,
  payload: data,
});

export const checkGeneralErrorValue = (data) => ({
  type: ApplicationActionTypes.CHECK_GENERAL_ERROR_VALUE,
  payload: data,
});

export const checkGeneralErrorAsync = (dispatch, callbackFn) => {
  fetchGetJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/CheckGeneralError`)
    .then((response) => {
      dispatch(checkGeneralErrorValue(response));
      if (!response.showGeneralError) {
        callbackFn();
      }
    })
    .catch(() => dispatch(generalServerFailure()));
};

const handleFetchError = (dispatch) => (error) => {
  if (error === 400) {
    dispatch(
      reloadRequiredFailure({
        reloadRequiredMessage: i18n.t('error.reload-required-message.new-version'),
        reloadRequiredButtonText: i18n.t('error.reload-required-button-text.new-version'),
      }),
    );
  } else if (error === 401) {
    dispatch(
      reloadRequiredFailure({
        reloadRequiredMessage: i18n.t('error.reload-required-message.authentication'),
        reloadRequiredButtonText: i18n.t('error.reload-required-button-text.authentication'),
      }),
    );
  } else {
    checkGeneralErrorAsync(dispatch);
  }
};

export const resetApplicationState = () => ({
  type: ApplicationActionTypes.RESET_APPLICATION_STATE,
});

export const goToPreviousStep = (currentStepData) => ({
  type: ApplicationActionTypes.GO_TO_PREVIOUS_STEP,
  payload: currentStepData,
});

export const setErrorOccured = (errorOccured) => ({
  type: ApplicationActionTypes.SET_ERROR_OCCURED,
  payload: { errorOccured },
});

export const setAcceptedConsent = (data) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_CONSENT,
  payload: data,
});

export const setAcceptedPersonalDataConsent = (acceptedPersonalDataConsent) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_PERSONAL_DATA_CONSENT,
  payload: { acceptedPersonalDataConsent },
});

export const setAcceptedBoricaConsent = (acceptedBoricaConsent) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_BORICA_CONSENT,
  payload: { acceptedBoricaConsent },
});

export const setAcceptedPaymentServicesConsent = (acceptedPaymentServicesConsent) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_PAYMENT_SERVICES_CONSENT,
  payload: { acceptedPaymentServicesConsent },
});

export const setAcceptedOnlineIdentificationConsent = (acceptedOnlineIdentificationConsent) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_ONLINE_IDENTIFICATION_CONSENT,
  payload: { acceptedOnlineIdentificationConsent },
});

export const saveClientDataStart = () => ({
  type: ApplicationActionTypes.SAVE_CLIENT_DATA_START,
});

export const saveClientDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_CLIENT_DATA_SUCCESS,
  payload: data,
});

export const resetInvalidEmployeeId = () => ({
  type: ApplicationActionTypes.RESET_INVALID_EMPLOYEE_ID,
});

export const setSmsVerificationCode = (smsVerificationCode) => ({
  type: ApplicationActionTypes.SET_SMS_CODE_VERIFICATION,
  payload: { smsVerificationCode },
});

export const saveClientDataAsync = (data, dispatch) => {
  dispatch(saveClientDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveClientData`, data)
    .then((response) => {
      dispatch(saveClientDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const generateVerificationCodeStart = () => ({
  type: ApplicationActionTypes.GENERATE_VERIFICATION_CODE_START,
});

export const generateVerificationCodeSuccess = (data) => ({
  type: ApplicationActionTypes.GENERATE_VERIFICATION_CODE_SUCCESS,
  payload: data,
});

export const generateVerificationCodeAsync = (data, dispatch, callbackFn) => {
  dispatch(generateVerificationCodeStart());

  return fetchGetJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/GenerateVerificationCode`,
  )
    .then((response) => {
      dispatch(generateVerificationCodeSuccess(response));
      callbackFn(!response.requestCannotContinue);
    })
    .catch(handleFetchError(dispatch));
};

export const changeGenericConsent = (data) => ({
  type: ApplicationActionTypes.CHANGE_GENERIC_CONSENT,
  payload: data,
});

export const saveGeneralTermsDataStart = () => ({
  type: ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_START,
});

export const saveGeneralTermsDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_SUCCESS,
  payload: data,
});

export const saveGeneralTermsDataAsync = (data, dispatch) => {
  dispatch(saveGeneralTermsDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveGeneralTerms`, data)
    .then((response) => {
      dispatch(saveGeneralTermsDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveAdditionalDataStart = () => ({
  type: ApplicationActionTypes.SAVE_ADDITIONAL_DATA_START,
});

export const saveAdditionalDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_ADDITIONAL_DATA_SUCCESS,
  payload: data,
});

export const saveAdditionalDataAsync = (data, dispatch) => {
  dispatch(saveAdditionalDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveAdditionalData`, data)
    .then((response) => {
      dispatch(saveAdditionalDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const loadDocumentsDataStart = () => ({
  type: ApplicationActionTypes.LOAD_DOCUMENTS_DATA_START,
});

export const loadDocumentsDataSuccess = (data) => ({
  type: ApplicationActionTypes.LOAD_DOCUMENTS_DATA_SUCCESS,
  payload: data,
});

export const loadDocumentsDataAsync = (dispatch) => {
  dispatch(loadDocumentsDataStart());

  const loadDocumentsData = () => {
    fetchGetJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/LoadDocumentsData`)
      .then((response) => {
        if (response.errorOccured) {
          dispatch(generalServerFailure(response));
        } else {
          dispatch(loadDocumentsDataSuccess(response));
        }
      })
      .catch(handleFetchError(dispatch));
  };

  loadDocumentsData();
};

export const saveDocumentsDataStart = () => ({
  type: ApplicationActionTypes.SAVE_DOCUMENTS_DATA_START,
});

export const saveDocumentsDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_DOCUMENTS_DATA_SUCCESS,
  payload: data,
});

export const saveDocumentsDataAsync = (data, dispatch) => {
  dispatch(saveDocumentsDataStart());
  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveDocumentsData`, data)
    .then((response) => {
      dispatch(saveDocumentsDataSuccess(response));
    })
    .catch(handleFetchError(dispatch));
};

export const validateDocumentsSigningCodeStart = () => ({
  type: ApplicationActionTypes.VALIDATE_DOCUMENTS_SIGNING_CODE_START,
});

export const validateDocumentsSigningCodeSuccess = (data) => ({
  type: ApplicationActionTypes.VALIDATE_DOCUMENTS_SIGNING_CODE_SUCCESS,
  payload: data,
});

export const validateDocumentsSigningCodeAsync = (data, dispatch) => {
  dispatch(validateDocumentsSigningCodeStart());

  fetchPostJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/ValidateDocumentsSigningCode`,
    data,
  )
    .then((response) => {
      dispatch(validateDocumentsSigningCodeSuccess(response));
    })
    .catch(handleFetchError(dispatch));
};

export const saveIdentificationDataStart = () => ({
  type: ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_START,
});

export const saveIdentificationDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_SUCCESS,
  payload: data,
});

export const saveIdentificationDataAsync = (data, dispatch) => {
  dispatch(saveIdentificationDataStart());

  return fetchPostJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveIdentificationData`,
    data,
  )
    .then((response) => {
      dispatch(saveIdentificationDataSuccess({ ...data, ...response }));
      if (response.identificationUrl != null) {
        window.location.href = response.identificationUrl;
      }
      return response;
    })
    .catch(handleFetchError(dispatch));
};

export const setSelectedBusinessRelationPurpose = (data) => ({
  type: ApplicationActionTypes.SET_SELECTED_BUSINESS_RELATION_PURPOSE,
  payload: data,
});

export const setSelectedFundsOrigin = (data) => ({
  type: ApplicationActionTypes.SET_SELECTED_FUNDS_ORIGIN,
  payload: data,
});

export const saveRatingDataStart = () => ({
  type: ApplicationActionTypes.SAVE_RATING_DATA_START,
});

export const saveRatingDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_RATING_DATA_SUCCESS,
  payload: data,
});

export const saveRatingDataAsync = (data, dispatch) => {
  dispatch(saveRatingDataStart());
  return fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveRatingData`, data)
    .then((response) => {
      dispatch(saveRatingDataSuccess({ ...(data.rating && data), ...response }));
      return response;
    })
    .catch(handleFetchError(dispatch));
};

export const startSessionStart = () => ({
  type: ApplicationActionTypes.START_SESSION_START,
  payload: { loadingSessionData: true },
});

export const startSessionSuccess = (data) => ({
  type: ApplicationActionTypes.START_SESSION_SUCCESS,
  payload: {
    ...data,
    loadingSessionData: false,
  },
});

export const startSessionAsync = (queryString, dispatch, callbackFn) => {
  dispatch(startSessionStart());

  fetchGetJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/Get${queryString}`)
    .then((response) => {
      dispatch(startSessionSuccess(response));
      callbackFn(
        response.activeStep || response.nomenclatures.steps[0].nameEN,
        response.nomenclatures.steps,
        response.requestCannotContinue,
        response.nomenclatures.onlinePaymentAccountProcessId,
      );
    })
    .catch(handleFetchError(dispatch));
};

export const signOut = () => {
  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/SignOut`);
};

export const completeIdentificationStart = () => ({
  type: ApplicationActionTypes.COMPLETE_IDENTIFICATION_START,
});

export const completeIdentificationSuccess = (data) => ({
  type: ApplicationActionTypes.COMPLETE_IDENTIFICATION_SUCCESS,
  payload: data,
});

export const completeIdentificationAsync = (data, dispatch, nextFn) => {
  dispatch(completeIdentificationStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/CompleteIdentification`, data)
    .then((response) => {
      dispatch(completeIdentificationSuccess(response));
      nextFn();
    })
    .catch(handleFetchError(dispatch));
};

export const setDocumentsSigningCode = (documentsSigningCode) => ({
  type: ApplicationActionTypes.SET_DOCUMENTS_SIGNING_CODE,
  payload: { documentsSigningCode },
});

export const generateDocumentsSigningCodeStart = () => ({
  type: ApplicationActionTypes.GENERATE_DOCUMENT_SIGNING_CODE_START,
});

export const generateDocumentsSigningCodeSuccess = (data) => ({
  type: ApplicationActionTypes.GENERATE_DOCUMENT_SIGNING_CODE_SUCCESS,
  payload: data,
});

export const generateDocumentsSigningCodeAsync = (dispatch, callbackFn) => {
  dispatch(generateDocumentsSigningCodeStart());

  return fetchGetJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/GenerateDocumentsSigningCode`,
  )
    .then((response) => {
      dispatch(generateDocumentsSigningCodeSuccess(response));
      callbackFn();
    })
    .catch(handleFetchError(dispatch));
};

export const setUtmMedium = (data) => ({
  type: ApplicationActionTypes.SET_UTM_MEDIUM,
  payload: { utmMedium: data.utmMedium },
});

export const setProcessId = (processId, showEntryStep) => ({
  type: ApplicationActionTypes.SET_PROCESS_ID,
  payload: { processId, showEntryStep },
});

export const getStepsByProcessStart = () => ({
  type: ApplicationActionTypes.GET_STEPS_BY_PROCESS_START,
});

export const getStepsByProcessSuccess = (data) => ({
  type: ApplicationActionTypes.GET_STEPS_BY_PROCESS_SUCCESS,
  payload: data,
});

export const getStepsByProcessAsync = (processId, dispatch) => {
  dispatch(getStepsByProcessStart());

  fetchGetJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/GetStepsByProcess?processId=${processId}`,
  )
    .then((response) => {
      dispatch(getStepsByProcessSuccess(response));
    })
    .catch(handleFetchError(dispatch));
};

export const savePackageDataStart = () => ({
  type: ApplicationActionTypes.SAVE_PACKAGE_DATA_START,
});

export const savePackageDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_PACKAGE_DATA_SUCCESS,
  payload: data,
});

export const savePackageDataAsync = (data, dispatch) => {
  dispatch(savePackageDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SavePackageData`, data)
    .then((response) => {
      dispatch(savePackageDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const setSelectedPackageId = (data) => ({
  type: ApplicationActionTypes.SET_SELECTED_PACKAGE_ID,
  payload: data,
});

export const setRateScore = (data) => {
  return {
    type: ApplicationActionTypes.SET_RATE_SCORE,
    payload: data,
  };
};

export const setDeliveryBranchId = (data) => ({
  type: ApplicationActionTypes.SET_DELIVERY_BRANCH_ID,
  payload: data,
});

export const setSelectedDebitAddress = (data) => ({
  type: ApplicationActionTypes.SET_SELECTED_DEBIT_CARD_ADDRESS,
  payload: data,
});

export const setCurrentField = (data) => ({
  type: ApplicationActionTypes.SET_CURRENT_FIELD,
  payload: data,
});

export const saveDebitCardDataStart = () => ({
  type: ApplicationActionTypes.SAVE_DEBIT_CARD_DATA_START,
});

export const saveDebitCardDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_SAVE_DEBIT_CARD_DATA_SUCCESS,
  payload: data,
});

export const saveDebitCardDataAsync = (data, dispatch) => {
  dispatch(saveDebitCardDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveDebitCardData`, data)
    .then((response) => {
      dispatch(saveDebitCardDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const addOcrFile = (data) => ({
  type: ApplicationActionTypes.ADD_OCR_FILE,
  payload: data,
});

export const deleteOcrFile = (data) => ({
  type: ApplicationActionTypes.DELETE_OCR_FILE,
  payload: data,
});

export const saveUploadDocumentsStart = () => ({
  type: ApplicationActionTypes.SAVE_UPLOAD_DOCUMENTS_START,
});

export const saveUploadDocumentsSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_UPLOAD_DOCUMENTS_SUCCESS,
  payload: data,
});

export const saveUploadedDocumentsAsync = (data, dispatch) => {
  dispatch(saveUploadDocumentsStart());

  fetchPostFormData(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/UploadCapitalAccountDocuments`,
    data,
  )
    .then((response) => {
      dispatch(saveUploadDocumentsSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const confirmOcrDataStart = () => ({
  type: ApplicationActionTypes.CONFIRM_OCR_DATA_START,
});

export const confirmOcrDataSuccess = (data) => ({
  type: ApplicationActionTypes.CONFIRM_OCR_DATA_SUCCESS,
  payload: data,
});

export const confirmOcrDataAsync = (dispatch) => {
  dispatch(confirmOcrDataStart());
  fetchGetJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/ConfirmCapitalAccountOcrData`)
    .then((response) => {
      dispatch(confirmOcrDataSuccess(response));
    })
    .catch(handleFetchError(dispatch));
};

export const checkOcrAtteptsCountStart = () => ({
  type: ApplicationActionTypes.CHECK_OCR_ATTEMPTS_COUNT_START,
});

export const checkOcrAtteptsCountSuccess = (data) => ({
  type: ApplicationActionTypes.CHECK_OCR_ATTEMPTS_COUNT_SUCCESS,
  payload: data,
});

export const checkOcrAttemptsCountAsync = (dispatch) => {
  dispatch(checkOcrAtteptsCountStart());
  fetchGetJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/CheckOcrAttemptsCount`)
    .then((response) => {
      dispatch(checkOcrAtteptsCountSuccess(response));
    })
    .catch(handleFetchError(dispatch));
};

export const goToNextStep = (currentStepData) => ({
  type: ApplicationActionTypes.GO_TO_NEXT_STEP,
  payload: currentStepData,
});

export const setValidFileNameFlag = (data) => ({
  type: ApplicationActionTypes.SET_VALID_FILE_NAME_FLAG,
  payload: data,
});
