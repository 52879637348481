import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import { Rating, Button } from '@mui/material';
import useStyles from './request-success.styles';
import Honeycomb from '../../../assets/images/honeycomb.svg';
import { saveRatingDataAsync } from '../../../providers/application/application.actions';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import RatingDialog from '../../rating-dialog/rating-dialog';
import { ReactComponent as RoundGlowingCheck } from '../../../assets/images/round-glowing-check.svg';
import { ReactComponent as RatingStar } from '../../../assets/images/rating-star.svg';
import { ReactComponent as Smile } from '../../../assets/images/smile-icon.svg';
import { ReactComponent as AppleStore } from '../../../assets/images/apple-store.svg';
import { ReactComponent as PlayStore } from '../../../assets/images/play-store.svg';
import DskBusiness from '../../../assets/images/dsk-business.png';

const RequestSuccess = () => {
  const { t } = useTranslation();
  const {
    rating,
    isFreeLancer,
    processId,
    nomenclatures: {
      greenCertificateUrl,
      capitalAccountProcessId,
      dskBusinessAppStoreUrl,
      dskBusinessGooglePlayUrl,
    },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const showThanksMessage = !!(processId === capitalAccountProcessId && rating);
  const classes = useStyles(showThanksMessage);

  const [openNps, setOpenNps] = useState(false);

  const handleRatingChange = (e, newValue) => {
    saveRatingDataAsync({ rating: newValue }, dispatch);
    setOpenNps(true);
  };
  const handleRatingDialogClose = () => {
    setOpenNps(false);
  };

  const openAppStore = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <div style={{ position: showThanksMessage ? 'relative' : 'initial' }}>
      {processId === capitalAccountProcessId ? (
        <>
          {showThanksMessage && (
            <Grid item xs={12} className={classes.thanksMessage}>
              <Typography>{t('request-success.rating-success-message')}</Typography>
              <div style={{ marginTop: '36px' }}>
                <Smile />
              </div>
            </Grid>
          )}
          <Grid
            container
            spacing={2}
            className={classes.contentContainer}
            style={{ justifyContent: 'center' }}
          >
            <div>
              <div style={{ margin: '36px 0px 20px 0px' }}>
                <RoundGlowingCheck />
              </div>
              <Box className={classes.requestSuccessTitle}>
                {t('request-success-capital-account-complete-greeting')}
              </Box>
            </div>
            <div style={{ marginTop: '16px' }}>
              <Typography style={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }}>
                {t('request-success-capital-account-complete-title')}
              </Typography>
            </div>
            <div style={{ marginTop: '16px' }}>
              <Typography style={{ fontSize: '18px', lineHeight: '24px' }}>
                {t('request-success-capital-account-complete-message')}
              </Typography>
            </div>
            <div style={{ marginTop: '16px' }}>
              <Typography style={{ fontSize: '18px', lineHeight: '24px' }}>
                {t('request-success-capital-account-complete-subtitle')}
              </Typography>
            </div>
            <Grid className={classes.ratingSection}>
              <Grid item xs={12} sm={12}>
                <RatingStar />
                <Typography component='h6' variant='h6' color='#52AE30' fontWeight='600'>
                  {t('request-success.rating-title')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.ratingIconBox}>
                <Rating
                  className={classes.ratingIcons}
                  name='rating'
                  value={rating}
                  onChange={(e, newValue) => saveRatingDataAsync({ rating: newValue }, dispatch)}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container spacing={2} className={classes.contentContainer}>
          <div>
            <Box className={classes.requestSuccessTitle}>
              {t('request-success.completed-request')}
            </Box>
            <Box className={classes.requestSuccessSubtitle}>
              {t('request-success.completed-request.subtitle')}
            </Box>
          </div>
          <div className={classes.combineTextContentContainer}>
            <Typography className={classes.requestSuccessTextLine1}>
              {isFreeLancer
                ? t('request-success.completed-request.freelancer-line1')
                : t('request-success.completed-request.line1')}
            </Typography>

            <Typography className={classes.requestSuccessTextLine2}>
              <Trans>{t('request-success.completed-request.line2')}</Trans>
            </Typography>
          </div>
          {greenCertificateUrl && (
            <Grid item xs={12} className={`${classes.greenSection} ${classes.boxPadding}`}>
              <Box className={classes.greenBox}>
                <Grid
                  className={classes.gridItem}
                  item
                  xs={12}
                  style={{ marginBottom: '14px', alignItems: 'center' }}
                >
                  <img src={Honeycomb} alt='Honeycomb' height={96} width={96} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography className={classes.greenTitle}>
                    {t('request-success.green-title')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography component='h6' variant='h6' color='#151617'>
                    <Trans>{t('request-success.green-subtitle')}</Trans>
                  </Typography>
                </Grid>
              </Box>
              <Box className={classes.greenButtonBox}>
                <Button className={classes.greenButton} href={greenCertificateUrl} target='_blank'>
                  {t('request-success.green-button-title')}
                </Button>
              </Box>
            </Grid>
          )}
          <Grid className={classes.businessAppStore}>
            <div className={classes.businessAppStoreRows}>
              <div className={classes.playStoreRow}>
                <Typography className={classes.appStoreHeader}>
                  {t('request-success.appStore-header')}
                </Typography>
                <Grid
                  id='btn_AppleStore'
                  onClick={() => openAppStore(dskBusinessAppStoreUrl)}
                  className={classes.playStoreButtonsContainer}
                  style={{
                    marginBottom: '12px',
                  }}
                >
                  <AppleStore />
                </Grid>
                <Grid
                  id='btn_GooglePlayStore'
                  onClick={() => openAppStore(dskBusinessGooglePlayUrl)}
                  className={classes.playStoreButtonsContainer}
                >
                  <PlayStore />
                </Grid>
              </div>
              <div className={classes.dskBusinessImageContainer}>
                <img alt='dsk-business' style={{ width: '100%' }} src={DskBusiness} />
              </div>
            </div>
          </Grid>
          <Grid className={classes.ratingSection}>
            <Grid item xs={12}>
              <Typography component='h6' variant='h6' color='#52AE30' fontWeight='600'>
                {t('request-success.rating-title')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.ratingIconBox}>
              <Rating
                className={classes.ratingIcons}
                name='rating'
                value={rating}
                onChange={handleRatingChange}
              />
            </Grid>
          </Grid>
          <RatingDialog open={openNps} handleClose={handleRatingDialogClose} />
        </Grid>
      )}
    </div>
  );
};

export default RequestSuccess;
