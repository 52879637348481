import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Radio } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from './packages-data-step.styles';
import {
  savePackageDataAsync,
  setSelectedPackageId,
} from '../../../providers/application/application.actions';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../../providers/application/application.provider';
import { ReactComponent as Check } from '../../../assets/images/check_icon.svg';
import PackageDialog from '../../package-dialog/package-dialog.component';

const PackagesDataStep = ({ formRef }) => {
  const classes = useStyles();
  const dispatch = useApplicationDispatch();
  const { t } = useTranslation();
  const {
    packageId,
    nomenclatures: { packages },
  } = useApplicationState();
  const { handleSubmit } = useForm();

  const [showPackageDialog, setShowPackageDialog] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const packageDialogOpen = (id) => {
    setSelectedPackage(() => packages.find((p) => p.id === id));
    setShowPackageDialog(true);
  };

  const packageDialogClose = () => {
    setShowPackageDialog(false);
  };

  const onSubmit = () => {
    savePackageDataAsync({ packageId }, dispatch);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <div>
        {packages.map((p) => {
          const formatDescription = p.description.split('<br/>');
          return (
            <Accordion className={classes.roundedAccordion} key={`panel${p.id}`}>
              <AccordionSummary
                className={classes.roundedAccordionSummary}
                classes={{ expanded: classes.expanded }}
                expandIcon={<ExpandMoreIcon style={{ color: '#52AE30', pointerEvents: 'auto' }} />}
                key={`summary${p.shortNameEn}`}
              >
                <div className={classes.summaryContainer}>
                  <Radio
                    onClick={(e) => e.stopPropagation()}
                    style={{ pointerEvents: 'auto' }}
                    checked={packageId === p.id}
                    onChange={(e) => {
                      dispatch(setSelectedPackageId(Number(e.target.value)));
                    }}
                    value={p.id}
                  />
                  <div style={{ marginLeft: '24px' }}>
                    <Trans>
                      <Typography className={classes.packageText}>{p.name}</Typography>
                      <Typography className={classes.priceText}>{p.price}</Typography>
                    </Trans>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.roundedAccordionDetails} key={`delais${p.id}`}>
                {formatDescription.map((offer, index) => (
                  <div key={`${p.shortNameEn + index}`} className={classes.offerContainer}>
                    <div>
                      <Check style={{ width: '12.65px', height: '9px' }} />
                    </div>
                    <Typography className={classes.stepsDescriptionText}>
                      <Trans>{offer}</Trans>
                    </Typography>
                  </div>
                ))}
                <div className={classes.packageModalButton}>
                  <Typography
                    className={classes.packageModalButtonText}
                    onClick={() => packageDialogOpen(p.id)}
                  >
                    {t('general.see-more')}
                  </Typography>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      <PackageDialog
        open={showPackageDialog}
        handleClose={packageDialogClose}
        selectedPackage={selectedPackage}
      />
    </form>
  );
};

export default PackagesDataStep;
