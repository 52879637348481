/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Trans, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import useStyles from './entry-step.styles';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../../providers/application/application.provider';
import {
  getStepsByProcessAsync,
  setProcessId,
  checkGeneralErrorAsync,
} from '../../../providers/application/application.actions';
import DskLogo from '../../../assets/images/dsk_logo.png';
import OtpLogoMobile from '../../../assets/images/otp-logo-mobile.png';
import OtpLogoTablet from '../../../assets/images/otp-logo-tablet.png';
import EntryStepButtons from '../entry-step-buttons/entry-step-buttons.component';
import InfoDialog from '../../info-dialog/info-dialog.component';
import HourglassIcon from '../../../assets/images/hourglass.svg';

const EntryStep = () => {
  const dispatch = useApplicationDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    nomenclatures: { capitalAccountProcessId, disableCapitalAccountProcess },
  } = useApplicationState();

  const { t } = useTranslation();

  const [showCapitalAccountMessage, setShowCapitalAccountMessage] = useState(false);

  const combineProcessId = (processId) => {
    dispatch(setProcessId(processId, false));
    getStepsByProcessAsync(processId, dispatch);
  };

  const handleButtonClick = (processId) => {
    if (processId === capitalAccountProcessId && disableCapitalAccountProcess) {
      setShowCapitalAccountMessage(true);
    } else {
      checkGeneralErrorAsync(dispatch, () => combineProcessId(processId));
    }
  };

  const handleLogoClick = () => {
    navigate(`/`);
  };

  return (
    <>
      <Grid container className={classes.contentContainer}>
        <Grid item xs={12} className={classes.dskLogoMobile} onClick={handleLogoClick}>
          <img src={DskLogo} style={{ width: '160px' }} alt='logo' />
        </Grid>
        {!desktop && (
          <div className={classes.otpLogoContainer}>
            <img
              style={{ width: mobile ? '180px' : '240px', height: mobile ? '180px' : '240px' }}
              src={mobile ? OtpLogoMobile : OtpLogoTablet}
              alt='logo-mobile'
            />
          </div>
        )}
        <Grid item xs={12} style={{ paddingLeft: '0' }}>
          <Box className={classes.header}>
            <Trans>{t('entry-step.header-part1')}</Trans>
            <span className={classes.greenText}>{t('entry-step.header-part2')}</span>
            <Trans> {t('entry-step.header-part3')}</Trans>
          </Box>
          <Box className={classes.subheader}>{t('entry-step.subheader')}</Box>
        </Grid>
        <EntryStepButtons handleButtonClick={handleButtonClick} />
      </Grid>
      <InfoDialog
        open={showCapitalAccountMessage}
        title={t('entry-step.capital-account-modal-message')}
        handleClose={() => setShowCapitalAccountMessage(false)}
        icon={HourglassIcon}
      />
    </>
  );
};

export default EntryStep;
