import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  TextField,
  Radio,
  Typography,
  Divider,
  Autocomplete,
  FormControlLabel,
} from '@mui/material';
import useStyles from './debit-card-step.styles';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../../providers/application/application.provider';
import {
  setDeliveryBranchId,
  setSelectedDebitAddress,
  setCurrentField,
  saveDebitCardDataAsync,
} from '../../../providers/application/application.actions';
import { requiredTextField } from '../../../utils/validation.utils';
import { ReactComponent as ArrowDown } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as Pin } from '../../../assets/images/otp-pin.svg';
import CustomPopper from '../custom-popper/custom-popper.component';

const DebitCardStep = ({ formRef }) => {
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    getValues,
    clearErrors,
    reset,
  } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    selectedAddressType,
    cardAddresses,
    cardCompanyName,
    cardHolderName,
    cardDeliveryAddress,
    deliveryBranchId,
    nomenclatures: { branches },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [formData, setFormData] = useState({
    cardCompanyName,
    settlement: '',
    location: '',
    postCode: '',
  });

  useEffect(() => {
    // HACK: React Hook Form is unable to automatically focus errors in Firefox.
    if (errors && Object.keys(errors).length > 0) {
      const element = document.getElementsByClassName('Mui-error')[0];
      if (element) {
        const elementRect = element.getBoundingClientRect();
        window.scrollTo(0, elementRect.top + window.scrollY - window.innerHeight / 2);
      }
    }
  }, [errors]);

  const onSubmit = () => {
    const data = getValues();
    const constructData = {
      companyName: data.cardCompanyName.toUpperCase(),
      cardHolderName,
      deliveryBranchId,
      deliveryAddressTypeId: selectedAddressType,
      deliveryAddress: data.location,
      deliveryCity: data.settlement,
      deliveryPostcode: data.postCode,
    };
    saveDebitCardDataAsync(constructData, dispatch);
  };

  const handleFieldChange = (name, value) => {
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    dispatch(setCurrentField({ name, value }));
  };

  const COMPANY_NAME_LENGTH = 20;
  const deliveryBranch = branches.find((br) => br.id === deliveryBranchId) || null;

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className={classes.formContainer}>
        <Grid item xs={12}>
          {cardAddresses.map((dc) => (
            <div
              key={`addressType${dc.addressTypeId}`}
              className={`${classes.radioRaw} ${
                selectedAddressType === dc.addressTypeId ? classes.activeRow : ''
              }`}
            >
              <FormControlLabel
                className={classes.radioButtonLabel}
                {...register('selectedAddressType', {
                  required: {
                    value: true,
                    message: t('validation.field.required'),
                  },
                })}
                control={
                  <Radio
                    checked={selectedAddressType === dc.addressTypeId}
                    onClick={(e) => {
                      dispatch(setSelectedDebitAddress(Number(e.target.value)));
                      setFormData((prevState) => ({
                        ...prevState,
                        settlement: '',
                        location: '',
                        postCode: '',
                      }));
                      clearErrors();
                      reset({ selectedAddressType: '' });
                    }}
                    value={dc.addressTypeId}
                    {...register('selectedAddressType', {
                      required: {
                        value: true,
                        message: t('validation.field.required'),
                      },
                    })}
                  />
                }
              />
              <Divider orientation='vertical' flexItem className={classes.divider} />
              <Typography className={classes.radioLabel}>{dc.addressTypeDescription}</Typography>
            </div>
          ))}
        </Grid>
        {errors.selectedAddressType && (
          <p style={{ marginLeft: '14px' }} className='radioButtonsErrorMessage'>
            {errors.selectedAddressType.message}
          </p>
        )}
        {selectedAddressType && (
          <div className={classes.debitCardForm}>
            <Grid item xs={12} className={classes.inputRow}>
              <TextField
                label={t('general-settlement')}
                value={
                  formData.settlement ||
                  (cardDeliveryAddress && cardDeliveryAddress.settlement) ||
                  ''
                }
                error={!!errors.settlement}
                helperText={errors.settlement && errors.settlement.message}
                inputProps={{
                  maxLength: 40,
                }}
                {...register('settlement', {
                  onChange: (e) => handleFieldChange(e.target.name, e.target.value),
                  validate: (value) => {
                    handleFieldChange('settlement', value);
                    return !!value.trim() || t('validation.field.required');
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputRow}>
              <TextField
                label={t('general-location')}
                value={
                  formData.location || (cardDeliveryAddress && cardDeliveryAddress.location) || ''
                }
                error={!!errors.location}
                helperText={errors.location && errors.location.message}
                inputProps={{
                  maxLength: 100,
                }}
                {...register('location', {
                  onChange: (e) => handleFieldChange(e.target.name, e.target.value),
                  validate: (value) => {
                    handleFieldChange('location', value);
                    return !!value.trim() || t('validation.field.required');
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputRow}>
              <TextField
                label={t('general-post-code')}
                value={
                  formData.postCode || (cardDeliveryAddress && cardDeliveryAddress.postCode) || ''
                }
                error={!!errors.postCode}
                helperText={errors.postCode && errors.postCode.message}
                inputProps={{
                  maxLength: 4,
                }}
                {...register('postCode', {
                  onChange: (e) => handleFieldChange(e.target.name, e.target.value),
                  validate: (value) => {
                    const regexValue = /^[1-9][0-9]{3}$/;
                    handleFieldChange('postCode', value);
                    if (!value.trim()) {
                      return t('validation.field.required');
                    }
                    if (!value.match(regexValue)) {
                      return t('validation.invalid-bg-post-code', { count: '4' });
                    }
                    return !!value.trim();
                  },
                })}
              />
            </Grid>
          </div>
        )}
        <div className={classes.iconDivider}>
          <Pin />
        </div>
        <Typography className={classes.bankOfficeSelectionHeader}>
          {t('debit-card.bank-office-service-info')}
        </Typography>
        <Grid item xs={12} className={classes.bankOfficeSelection}>
          <Controller
            name='deliveryBranch'
            defaultValue={deliveryBranch}
            render={({ field }) => (
              <Autocomplete
                noOptionsText={t('general.autocomplete-no-options')}
                PopperComponent={CustomPopper}
                popupIcon={<ArrowDown />}
                value={field.value}
                options={branches}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) =>
                  (!!value.id && value.id === option.id) || !value
                }
                onChange={(_, value) => {
                  field.onChange(value);
                  dispatch(setDeliveryBranchId(value));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('general-branch')}
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.deliveryBranch}
                    helperText={errors.deliveryBranch && errors.deliveryBranch.message}
                    placeholder={t('general-branch-placeholder')}
                  />
                )}
              />
            )}
            control={control}
            rules={{
              validate: (value) => !!value || t('validation.field.required'),
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.companyNameInput}>
          <TextField
            label={t('general-company-latin-name')}
            defaultValue={formData.cardCompanyName || cardCompanyName}
            error={!!errors.cardCompanyName}
            helperText={
              errors.cardCompanyName ? (
                errors.cardCompanyName.message
              ) : (
                <span
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <span>{t('debit-card.company-name-info')}</span>
                  <span>{`${
                    (formData.cardCompanyName && formData.cardCompanyName.length) || 0
                  }/${COMPANY_NAME_LENGTH}`}</span>
                </span>
              )
            }
            {...register('cardCompanyName', {
              onChange: (e) => handleFieldChange(e.target.name, e.target.value),
              validate: requiredTextField,
              pattern: {
                value: /^[\w\s-.]+$/,
                message: t('validation.text-field.latin-required'),
              },
            })}
            inputProps={{
              maxLength: 20,
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.cardHolderBox}>
          <Typography className={classes.cardHolderLabel}>
            {t('debit-card.holder-name-info')}
          </Typography>
          <Typography className={classes.cardHolderName}>{cardHolderName}</Typography>
        </Grid>
      </div>
    </form>
  );
};

export default DebitCardStep;
