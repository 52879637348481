/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Divider } from '@mui/material';
import Info from '../../info/info.component';
import { useApplicationState } from '../../../providers/application/application.provider';

const PhoneNumber = (p) => {
  const { defaultValue, propertyName, handleChange, field, errors, disabled } = p;
  const { t } = useTranslation();

  const { verificationCodeValidityInMinutes } = useApplicationState();

  return (
    <TextField
      label={t('client-data-step.mobile-phone-number')}
      defaultValue={defaultValue}
      name={propertyName}
      disabled={disabled}
      onChange={handleChange}
      field={field}
      InputProps={{
        startAdornment: (
          <>
            <InputAdornment position='start'>+359</InputAdornment>
            <Divider sx={{ height: 28, m: 0.5, marginRight: '8px' }} orientation='vertical' />
          </>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <Info
              title={t('client-data-step.mobile-phone-number')}
              content={t('client-data-step.mobile-phone-number-info-text', {
                verificationCodeValidityInMinutes,
              })}
            />
          </InputAdornment>
        ),
      }}
      inputProps={{ maxLength: 10 }}
      error={!!errors.mobilePhoneNumber}
      helperText={errors.mobilePhoneNumber && errors.mobilePhoneNumber.message}
    />
  );
};

export default PhoneNumber;
