import i18n from 'i18next';
import ApplicationActionTypes from './application.types';
import ClientDataImage from '../../assets/images/client-data-image.jpg';
import ConfirmationAndSigningDocumentsImage from '../../assets/images/confirmation-and-signing-documents-image.jpg';
import EIdentificationImage from '../../assets/images/e-identification-image.jpg';
import AdditionalDataImage from '../../assets/images/additional-data-image.jpg';
import PackagesImage from '../../assets/images/packages-image.jpg';
import CapitalAccountClientDataImage from '../../assets/images/capital-account-client-data-image.jpg';
import CapitalAccountEIdentificationImage from '../../assets/images/capital-account-e-identification-image.jpg';
import CapitalAccountAdditionalDataImage from '../../assets/images/capital-account-additional-data-image.jpg';

export const initialState = {
  requestNumber: '',
  token: '',
  clientIdentifier: '',
  representativeIdentifier: '',
  emailAddress: '',
  emailConfirmed: false,
  mobilePhoneNumber: '',
  mobilePhoneConfirmed: false,
  acceptedPersonalDataConsent: false,
  employeeIdentifier: '',
  showEntryStep: false,
  requestCannotContinue: false,
  clientNotFulfilledRequirementsForNewRequest: false,
  notAllowedLegalForm: false,
  invalidIdentificationDocument: false,
  confirmationEmailPending: false,
  resumeEmailPending: false,
  smsVerificationCode: '',
  generatedVerificationCode: '',
  shouldGenerateVerificationCode: false,
  verificationCodeValidityInMinutes: '',
  isClientIdentified: false,
  activeStep: '',
  loading: false,
  language: 'bg',
  errorOccured: false,
  reloadRequired: false,
  wrongSmsVerificationCode: false,
  expiredSmsVerificationCode: false,
  maxSmsVerificationCodeAttemptsReached: false,
  generateSmsVerificationCodeLoading: false,
  selectedBusinessRelationPurposes: [],
  isCashTransactionsBusinessRelationPurposeSelected: false,
  monthlyAmountCashTransactions: '',
  selectedFundsOrigin: [],
  isProvidedServicesFundsOriginSelected: false,
  providedServicesFundsOrigin: '',
  acceptedBoricaConsent: false,
  acceptedOnlineIdentificationConsent: false,
  acceptedPaymentServicesConsent: false,
  documents: [],
  isForPoliticallyExposedPerson: '',
  isForPoliticallyExposedRelatedPerson: '',
  companyName: '',
  nonFinancialEntity: '',
  isActiveNonFinancialEntity: '',
  awaitingIdentificationData: null,
  documentsSigningCode: '',
  showDocumentsVerificationData: false,
  insufficientCompanyData: false,
  doBeneficialOwnersHaveForeignCitizenship: '',
  invalidEmployeeID: false,
  pdfMimeType: 'application/pdf',
  allowedOnlyImages: ['image/jpeg', 'image/png'],
  nomenclatures: {
    businessRelationPurposes: [],
    amounts: [],
    fundsOrigins: [],
    businessRelationPurposesOther: '',
    businessRelationPurposesCashTransactions: '',
    fundsOriginProvidedServices: '',
    boricaIdentificationTermsUrl: '',
    boricaPersonalDataTermsUrl: '',
    phoneNumberAllowedPrefixes: [],
    nonFinancialEntities: [],
    accountServicesForBusinessClientsUrl: '',
    eChannelServicesForBusinessClientstUrl: '',
    callCenterPhoneNumber: '',
    businessClientsTariffUrl: '',
    greenCertificateUrl: '',
    packages: [],
    branches: [],
    steps: [],
    processes: [],
    onlinePaymentAccountProcessId: null,
    assistedPaymentAccountProcessId: null,
    capitalAccountProcessId: null,
    gdprConsentUrl: '',
    showPromoOffer: false,
    dskBusinessGooglePlayUrl: '',
    dskBusinessAppStoreUrl: '',
    disableCapitalAccountProcess: false,
  },
  defaultSideImageUrl: ClientDataImage,
  images: [
    {
      stepName: 'ClientData',
      imageUrl: ClientDataImage,
      imageUrlCapitalAccount: CapitalAccountClientDataImage,
    },
    {
      stepName: 'GeneralTerms',
      imageUrl: ConfirmationAndSigningDocumentsImage,
      imageUrlCapitalAccount: CapitalAccountClientDataImage,
    },
    {
      stepName: 'DocumentsUpload',
      imageUrlCapitalAccount: CapitalAccountEIdentificationImage,
    },
    {
      stepName: 'Identification',
      imageUrl: EIdentificationImage,
      imageUrlCapitalAccount: CapitalAccountEIdentificationImage,
    },
    {
      stepName: 'AdditionalData',
      imageUrl: AdditionalDataImage,
      imageUrlCapitalAccount: CapitalAccountAdditionalDataImage,
    },
    {
      stepName: 'DocumentsData',
      imageUrl: ConfirmationAndSigningDocumentsImage,
      imageUrlCapitalAccount: CapitalAccountAdditionalDataImage,
    },
    {
      stepName: 'DebitCard',
      imageUrl: AdditionalDataImage,
    },
    {
      stepName: 'Packages',
      imageUrl: PackagesImage,
    },
    {
      stepName: 'RegistrationCompletedSuccessfully',
      imageUrl: ClientDataImage,
      imageUrlCapitalAccount: CapitalAccountAdditionalDataImage,
    },
  ],
  isSessionStarted: false,
  adUtmSources: ['google', 'facebook', 'viber', 'nula.bg'],
  identificationUrl: '',
  loadingDocuments: false,
  errorMessageTitle: '',
  errorMessageText: '',
  rating: null,
  ratingSurvey: [
    {
      name: 'ratingFast',
      rateScore: null,
      title: i18n.t('request-success.rating-fast-title'),
      question: i18n.t('request-success.rating-fast-question'),
    },
    {
      name: 'ratingEasy',
      rateScore: null,
      title: i18n.t('request-success.rating-easy-title'),
      question: i18n.t('request-success.rating-easy-question'),
    },
    {
      name: 'ratingRecommended',
      rateScore: null,
      title: i18n.t('request-success.rating-recommended-title'),
      question: i18n.t('request-success.rating-recommended-question'),
    },
  ],
  isFreeLancer: false,
  loadingSessionData: false,
  generateDocumentsSigningCodeLoading: false,
  utmMedium: '',
  packageId: null,
  cardAddresses: [],
  selectedAddressType: null,
  deliveryBranchId: null,
  cardDeliveryAddress: null,
  cardCompanyName: '',
  cardHolderName: '',
  showGeneralError: false,
  processId: null,
  showOcrDataScreen: false,
  uploadedActFiles: [],
  uploadedProtocolFiles: [],
  ocrCompanyName: null,
  ocrCombinedAddress: null,
  ocrCompanyActivity: null,
  ocrOwnerName: null,
  ocrOwnerId: null,
  ocrCompanyCapital: null,
  ocrSigned: null,
  isOcrDataConfirmed: false,
  incorporationActValidName: true,
  incorporationProtocolValidName: true,
  mainDataInconsistency: false,
};
function applicationReducer(state, action) {
  switch (action.type) {
    case ApplicationActionTypes.RESET_APPLICATION_STATE: {
      return { ...initialState };
    }
    case ApplicationActionTypes.GO_TO_PREVIOUS_STEP: {
      const currentStepIndex = state.nomenclatures.steps.findIndex(
        (el) => el.nameEN === state.activeStep,
      );

      const previousStep = state.nomenclatures.steps[currentStepIndex - 1].nameEN;
      return {
        ...state,
        ...action.payload,
        activeStep: previousStep,
      };
    }
    case ApplicationActionTypes.SET_ACCEPTED_PERSONAL_DATA_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SAVE_CLIENT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_CLIENT_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case ApplicationActionTypes.RESET_INVALID_EMPLOYEE_ID: {
      return { ...state, invalidEmployeeID: false };
    }
    case ApplicationActionTypes.GENERAL_SERVER_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        generateSmsVerificationCodeLoading: false,
        errorOccured: true,
        errorMessageTitle: '',
        loadingDocuments: false,
        errorMessageText: '',
        loadingSessionData: false,
        generateDocumentsSigningCodeLoading: false,
      };
    }
    case ApplicationActionTypes.CHECK_GENERAL_ERROR_VALUE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ApplicationActionTypes.RELOAD_REQUIRED_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        generateSmsVerificationCodeLoading: false,
        reloadRequired: true,
        loadingSessionData: false,
        generateDocumentsSigningCodeLoading: false,
      };
    }
    case ApplicationActionTypes.CHANGE_GENERIC_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_START: {
      return {
        ...state,
        loading: true,
        wrongSmsVerificationCode: false,
        expiredSmsVerificationCode: false,
        maxSmsVerificationCodeAttemptsReached: false,
      };
    }
    case ApplicationActionTypes.SAVE_GENERAL_TERMS_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_ADDITIONAL_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_ADDITIONAL_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errorOccured: action.payload.awaitingIdentificationData,
        errorMessageTitle:
          action.payload.awaitingIdentificationData && i18n.t('general.loader-title'),
        errorMessageText:
          action.payload.awaitingIdentificationData &&
          i18n.t('identification-info.identification-pending-message-text'),
        documents: [],
      };
    }
    case ApplicationActionTypes.LOAD_DOCUMENTS_DATA_START: {
      return { ...state, loadingDocuments: true };
    }
    case ApplicationActionTypes.LOAD_DOCUMENTS_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loadingDocuments: false,
      };
    }
    case ApplicationActionTypes.SAVE_DOCUMENTS_DATA_START: {
      return { ...state, loadingDocuments: true };
    }
    case ApplicationActionTypes.SAVE_DOCUMENTS_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loadingDocuments: false,
      };
    }
    case ApplicationActionTypes.VALIDATE_DOCUMENTS_SIGNING_CODE_START: {
      return { ...state, loading: true, wrongSmsVerificationCode: false };
    }
    case ApplicationActionTypes.VALIDATE_DOCUMENTS_SIGNING_CODE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_IDENTIFICATION_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case ApplicationActionTypes.GENERATE_VERIFICATION_CODE_START: {
      return { ...state, generateSmsVerificationCodeLoading: true };
    }
    case ApplicationActionTypes.GENERATE_VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        generateSmsVerificationCodeLoading: false,
      };
    }
    case ApplicationActionTypes.SET_SELECTED_BUSINESS_RELATION_PURPOSE: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_SELECTED_FUNDS_ORIGIN: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.START_SESSION_START: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.START_SESSION_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isSessionStarted: true,
      };
    }
    case ApplicationActionTypes.SET_ERROR_OCCURED: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_ACCEPTED_BORICA_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_ACCEPTED_PAYMENT_SERVICES_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_ACCEPTED_ONLINE_IDENTIFICATION_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_SMS_CODE_VERIFICATION: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.GENERATE_DOCUMENT_SIGNING_CODE_START: {
      return { ...state, generateDocumentsSigningCodeLoading: true };
    }
    case ApplicationActionTypes.GENERATE_DOCUMENT_SIGNING_CODE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        documentsSigningCode: '',
        generateDocumentsSigningCodeLoading: false,
        wrongSmsVerificationCode: false,
      };
    }
    case ApplicationActionTypes.SET_ACCEPTED_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SAVE_RATING_DATA_START: {
      return { ...state };
    }
    case ApplicationActionTypes.SAVE_RATING_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ApplicationActionTypes.SET_DOCUMENTS_SIGNING_CODE: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.COMPLETE_IDENTIFICATION_START: {
      return { ...state, loadingSessionData: true };
    }
    case ApplicationActionTypes.COMPLETE_IDENTIFICATION_SUCCESS: {
      return { ...state, ...action.payload, isSessionStarted: true, loadingSessionData: false };
    }
    case ApplicationActionTypes.SET_UTM_MEDIUM: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SET_PROCESS_ID: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ApplicationActionTypes.GET_STEPS_BY_PROCESS_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.GET_STEPS_BY_PROCESS_SUCCESS: {
      return {
        ...state,
        nomenclatures: {
          ...state.nomenclatures,
          ...action.payload,
        },
        loading: false,
      };
    }
    case ApplicationActionTypes.SET_SELECTED_PACKAGE_ID: {
      return {
        ...state,
        packageId: action.payload,
      };
    }
    case ApplicationActionTypes.SAVE_PACKAGE_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_PACKAGE_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        documents: [],
        errorOccured: action.payload.awaitingIdentificationData,
        errorMessageTitle:
          action.payload.awaitingIdentificationData && i18n.t('general.loader-title'),
        errorMessageText:
          action.payload.awaitingIdentificationData &&
          i18n.t('identification-info.identification-pending-message-text'),
      };
    }
    case ApplicationActionTypes.SET_RATE_SCORE: {
      const updatedRatingSurvey = state.ratingSurvey.map((rs) =>
        rs.name === action.payload.name ? { ...rs, rateScore: action.payload.rateScore } : rs,
      );
      return {
        ...state,
        ratingSurvey: updatedRatingSurvey,
      };
    }
    case ApplicationActionTypes.SET_DELIVERY_BRANCH_ID: {
      return {
        ...state,
        deliveryBranchId: action.payload && action.payload.id,
      };
    }
    case ApplicationActionTypes.SET_SELECTED_DEBIT_CARD_ADDRESS: {
      const selectedAddress = state.cardAddresses.find((ca) => ca.addressTypeId === action.payload);
      return {
        ...state,
        selectedAddressType: action.payload,
        cardDeliveryAddress: selectedAddress,
      };
    }
    case ApplicationActionTypes.SET_CURRENT_FIELD: {
      if (action.payload.name === 'cardCompanyName') {
        return {
          ...state,
          cardCompanyName: action.payload.value,
        };
      }
      const updatedField = {
        ...state.cardDeliveryAddress,
        [action.payload.name]: action.payload.value,
      };
      return {
        ...state,
        cardDeliveryAddress: updatedField,
      };
    }
    case ApplicationActionTypes.SAVE_DEBIT_CARD_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_SAVE_DEBIT_CARD_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        documents: [],
      };
    }
    case ApplicationActionTypes.ADD_OCR_FILE: {
      const fileProps = {
        file: action.payload.uploadedFiles.file,
        blobFile: action.payload.uploadedFiles.blobFile,
        fileType: action.payload.uploadedFiles.fileType,
        errorMessage: action.payload.uploadedFiles.errorMessage,
      };

      if (action.payload.uploadedFiles.fileType === 'fileAct') {
        return {
          ...state,
          uploadedActFiles: [...state.uploadedActFiles, fileProps],
        };
      }
      return {
        ...state,
        uploadedProtocolFiles: [...state.uploadedProtocolFiles, fileProps],
      };
    }
    case ApplicationActionTypes.DELETE_OCR_FILE: {
      if (action.payload.type === 'fileAct') {
        const filteredActFiles = state.uploadedActFiles.filter(
          (el) => el.file.lastModified !== action.payload.id,
        );
        return {
          ...state,
          uploadedActFiles: filteredActFiles,
        };
      }
      const filteredProtocolFiles = state.uploadedProtocolFiles.filter(
        (el) => el.file.lastModified !== action.payload.id,
      );
      return {
        ...state,
        uploadedProtocolFiles: filteredProtocolFiles,
      };
    }
    case ApplicationActionTypes.SAVE_UPLOAD_DOCUMENTS_START: {
      return {
        ...state,
        loading: true,
        loadingDocuments: true,
      };
    }
    case ApplicationActionTypes.SAVE_UPLOAD_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        loadingDocuments: false,
        uploadedActFiles: !action.payload.incorporationActValidName ? [] : state.uploadedActFiles,
        uploadedProtocolFiles: !action.payload.incorporationProtocolValidName
          ? []
          : state.uploadedProtocolFiles,
      };
    }
    case ApplicationActionTypes.CONFIRM_OCR_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ApplicationActionTypes.CONFIRM_OCR_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case ApplicationActionTypes.CHECK_OCR_ATTEMPTS_COUNT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ApplicationActionTypes.CHECK_OCR_ATTEMPTS_COUNT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case ApplicationActionTypes.GO_TO_NEXT_STEP: {
      const currentStepIndex = state.nomenclatures.steps.findIndex(
        (el) => el.nameEN === state.activeStep,
      );
      const nextStep = state.nomenclatures.steps[currentStepIndex + 1].nameEN;
      return {
        ...state,
        ...action.payload,
        activeStep: nextStep,
      };
    }
    case ApplicationActionTypes.SET_VALID_FILE_NAME_FLAG: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default applicationReducer;
