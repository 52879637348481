import React, { Fragment, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Button, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useStyles from './documents-upload-step.styles';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { ReactComponent as RoundCheck } from '../../../assets/images/round-check.svg';
import { ReactComponent as RoundError } from '../../../assets/images/round_error.svg';
import { ReactComponent as WarningAmber } from '../../../assets/images/warning_amber.svg';
import { ReactComponent as DocumentAccept } from '../../../assets/images/document-accept.svg';
import Trashcan from '../../../assets/images/trashcan.png';
import {
  addOcrFile,
  deleteOcrFile,
  goToNextStep,
  saveUploadedDocumentsAsync,
  setValidFileNameFlag,
} from '../../../providers/application/application.actions';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../../providers/application/application.provider';
import { bytesToMegabytes, bytesToKilobytes, compressImage } from '../../../utils/common.utils';
import Loader from '../loader/loader.component';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const DocumentsUploadStep = ({ formRef }) => {
  const {
    uploadedProtocolFiles,
    uploadedActFiles,
    isOcrDataConfirmed,
    allowedOnlyImages,
    pdfMimeType,
    incorporationProtocolValidName,
    incorporationActValidName,
    loadingDocuments,
  } = useApplicationState();
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useApplicationDispatch();
  const inputFileAct = useRef(null);
  const inputFileProtocol = useRef(null);
  const { handleSubmit } = useForm();

  const allowedFiles = ['image/jpeg', 'image/png', 'application/pdf'];

  const exceedActFilesSize =
    uploadedActFiles.length > 0 &&
    Boolean(bytesToMegabytes(uploadedActFiles.reduce((acc, cur) => acc + cur.file.size, 0)) > 14);

  const exceedProtocolFilesSize =
    uploadedProtocolFiles.length > 0 &&
    Boolean(
      bytesToMegabytes(uploadedProtocolFiles.reduce((acc, cur) => acc + cur.file.size, 0)) > 14,
    );

  const notAllowedMixedTypesAct = Boolean(
    uploadedActFiles.length &&
      !uploadedActFiles.some((el) => Boolean(el.errorMessage)) &&
      uploadedActFiles.some((item) => allowedOnlyImages.indexOf(item.file.type) !== -1) &&
      uploadedActFiles.some((item) => item.file.type === pdfMimeType),
  );

  const notAllowedMixedTypesProtocol = Boolean(
    uploadedProtocolFiles.length &&
      !uploadedProtocolFiles.some((el) => Boolean(el.errorMessage)) &&
      uploadedProtocolFiles.some((item) => allowedOnlyImages.indexOf(item.file.type) !== -1) &&
      uploadedProtocolFiles.some((item) => item.file.type === pdfMimeType),
  );

  const handleFileUpload = async (e) => {
    const fileReader = new FileReader();
    const file = e.target.files[0];
    if (
      (e.target.name === 'fileAct' &&
        file &&
        (uploadedActFiles.some((el) => el.file.lastModified === file.lastModified) ||
          (file.type === pdfMimeType &&
            uploadedActFiles.some((el) => el.file.type === pdfMimeType)))) ||
      (e.target.name === 'fileProtocol' &&
        file &&
        (uploadedProtocolFiles.some((el) => el.file.lastModified === file.lastModified) ||
          (file.type === pdfMimeType &&
            uploadedProtocolFiles.some((el) => el.file.type === pdfMimeType)))) ||
      (file && file.size === 0)
    ) {
      return;
    }

    if (file) {
      const compressedBlob =
        allowedOnlyImages.indexOf(file.type) !== -1 && (await compressImage(file, 800, 0.9));

      const compressedFile = {
        blob: compressedBlob,
        name: file.name,
        type: file.type,
        size: compressedBlob.size,
        lastModified: file.lastModified,
      };

      fileReader.onload = () => {
        dispatch(
          addOcrFile({
            uploadedFiles: {
              blobFile: URL.createObjectURL(file),
              file: compressedBlob ? compressedFile : file,
              fileType: e.target.name,
              errorMessage:
                allowedFiles.indexOf(file.type) === -1
                  ? t('documents-upload-step.error-message-label')
                  : '',
            },
          }),
        );
      };
      fileReader.readAsDataURL(file);

      if (e.target.name === 'fileProtocol') {
        dispatch(setValidFileNameFlag({ incorporationProtocolValidName: true }));
      }

      if (e.target.name === 'fileAct') {
        dispatch(setValidFileNameFlag({ incorporationActValidName: true }));
      }
    }

    e.target.value = null;
  };

  const handleDeleteFile = (id, type) => {
    if (type === 'fileAct') {
      inputFileAct.current.value = null;
    } else {
      inputFileProtocol.current.value = null;
    }

    dispatch(deleteOcrFile({ id, type }));
  };

  const onSubmit = () => {
    if (isOcrDataConfirmed) {
      dispatch(goToNextStep());
      return;
    }
    const formData = new FormData();

    uploadedActFiles.forEach((el) => {
      formData.append(`IncorporationAct`, el.file.blob || el.file, el.file.name);
    });

    uploadedProtocolFiles.forEach((el) => {
      formData.append(`IncorporationProtocol`, el.file.blob || el.file, el.file.name);
    });

    saveUploadedDocumentsAsync(formData, dispatch);
  };

  const convertBytes = (fileSize) => {
    const sizeInMegabytes = bytesToMegabytes(fileSize);
    if (sizeInMegabytes > 1) {
      return `(${sizeInMegabytes.toFixed(2)} MB)`;
    }
    return `(${Math.round(bytesToKilobytes(fileSize))} KB)`;
  };

  const setUploadFileGeneralErrorTitle = (item) => {
    switch (true) {
      case item.exceedSizeLimit:
        return t('documents-upload-step.exceed-limit-label');
      case item.forbiddenMixingFiles:
        return t('documents-upload-step.error-type-header-label');
      case !item.isValidName:
        return t('documents-upload-step.valid-name-header-label');
      default:
        return '';
    }
  };

  const documentTypeLabelSetter = (type) => {
    const typeMapper = {
      fileAct: t('documents-upload-step.type-act'),
      fileProtocol: t('documents-upload-step.type-protocol'),
    };

    return typeMapper[type];
  };

  const setUploadFileGeneralErrorSubTitle = (item) => {
    switch (true) {
      case item.exceedSizeLimit:
        return t('documents-upload-step.exceed-limit-info');
      case item.forbiddenMixingFiles:
        return t('documents-upload-step.error-type-message-label');
      case !item.isValidName:
        return t('documents-upload-step.valid-name-message-label', {
          fileType: documentTypeLabelSetter(item.name),
        });
      default:
        return '';
    }
  };

  const uploadedDocuments = [
    {
      name: 'fileProtocol',
      label: t('documents-upload-step.protocol'),
      uploadedFiles: uploadedProtocolFiles,
      fileRef: inputFileProtocol,
      exceedSizeLimit: exceedProtocolFilesSize,
      forbiddenMixingFiles: notAllowedMixedTypesProtocol,
      isValidName: incorporationProtocolValidName,
    },
    {
      name: 'fileAct',
      label: t('documents-upload-step.act'),
      uploadedFiles: uploadedActFiles,
      fileRef: inputFileAct,
      exceedSizeLimit: exceedActFilesSize,
      forbiddenMixingFiles: notAllowedMixedTypesAct,
      isValidName: incorporationActValidName,
    },
  ];

  return loadingDocuments ? (
    <Grid container className={classes.loaderContainer}>
      <Loader title={t('general.loader-title')} message={t('ocr-documents.loader-message')} />
    </Grid>
  ) : (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      {isOcrDataConfirmed ? (
        <div className={classes.approvedDocumentsContainer}>
          <div>
            <DocumentAccept />
          </div>
          <div style={{ marginTop: '24px', textAlign: 'center' }}>
            <Typography style={{ fontWeight: 700, color: '#000' }}>
              {t('documents-upload-step.accepted-documents-title')}
            </Typography>
            <Typography style={{ color: '#7D828B', marginTop: '8px' }}>
              {t('documents-upload-step.accepted-documents-subtitle')}
            </Typography>
          </div>
        </div>
      ) : (
        <div>
          {uploadedDocuments.map((item) => (
            <div key={item.name} className={classes.uploadFilesContainer}>
              <Typography style={{ fontSize: '18px', fontWeight: 700 }}>{item.label}</Typography>
              {item.uploadedFiles.length > 0 && (
                <Typography style={{ color: '#7D828B', fontSize: '16px' }}>
                  {t('documents-upload-step.upload-document-note')}
                </Typography>
              )}
              {(item.exceedSizeLimit || item.forbiddenMixingFiles || !item.isValidName) && (
                <div className={classes.exceedUploadLimitContainer}>
                  <div>
                    <WarningAmber />
                  </div>
                  <div style={{ marginLeft: '8px', color: '#FF435A' }}>
                    <Typography style={{ fontSize: '18px', fontWeight: 700 }}>
                      {setUploadFileGeneralErrorTitle(item)}
                    </Typography>
                    <Typography className={classes.exceedUploadInfo}>
                      {setUploadFileGeneralErrorSubTitle(item)}
                    </Typography>
                  </div>
                </div>
              )}
              {item.uploadedFiles.length > 0 &&
                item.uploadedFiles.map((el) => (
                  <Fragment key={el.file.lastModified}>
                    <div className={classes.uploadedFilesRows}>
                      <div style={{ display: 'flex', alignItems: 'center', flexBasis: '70%' }}>
                        <div>{el.errorMessage ? <RoundError /> : <RoundCheck />}</div>
                        <Grid
                          onClick={() => window.open(el.blobFile, '_blank')}
                          className={
                            el.errorMessage ? classes.uploadErrorFile : classes.uploadFileName
                          }
                        >
                          {el.file.name}
                        </Grid>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className={classes.uploadFileSize}>{convertBytes(el.file.size)}</span>
                        <Grid onClick={() => handleDeleteFile(el.file.lastModified, el.fileType)}>
                          <img src={Trashcan} alt='trashcan' className={classes.trashCanIcon} />
                        </Grid>
                      </div>
                    </div>
                    <p className={classes.errorMessage}>{el.errorMessage && el.errorMessage}</p>
                    <Divider orientation='vertical' className={classes.divider} />
                  </Fragment>
                ))}
              <Button
                component='label'
                role={undefined}
                tabIndex={-1}
                startIcon={<PlusIcon />}
                className={classes.uploadButton}
              >
                {t('documents-upload-step.upload-label')}
                <VisuallyHiddenInput
                  ref={item.fileRef}
                  type='file'
                  accept='image/jpeg, image/png, application/pdf'
                  name={item.name}
                  onChange={handleFileUpload}
                />
              </Button>
            </div>
          ))}
        </div>
      )}
    </form>
  );
};

export default DocumentsUploadStep;
