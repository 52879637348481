import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    loaderContainer: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    uploadFilesContainer: css({
      background: '#fff',
      borderRadius: '32px',
      boxShadow: '0px 4px 8px 0px rgba(73, 92, 136, 0.15)',
      padding: '24px 16px',
      marginTop: '16px',
    }),
    approvedDocumentsContainer: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#fff',
      borderRadius: '32px',
      boxShadow: '0px 4px 8px 0px rgba(73, 92, 136, 0.15)',
      padding: '24px',
      marginTop: '24px',
    }),
    exceedUploadLimitContainer: css({
      display: 'flex',
      padding: '16px',
      backgroundColor: '#FFEBED',
      borderRadius: '16px',
      marginTop: '8px',
    }),
    exceedUploadInfo: css({
      fontSize: '16px',
      lineHeight: '20px',
      marginTop: '8px',
    }),
    uploadedFilesRows: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '18px',
    }),
    uploadFileName: css({
      cursor: 'pointer',
      marginLeft: '8px',
      fontSize: '14px',
      fontWeight: 600,
      textDecoration: 'underline',
      wordBreak: 'break-word',
    }),
    uploadErrorFile: css({
      color: '#FF435A',
      cursor: 'unset',
      pointerEvents: 'none',
      marginLeft: '8px',
      fontSize: '14px',
      fontWeight: 600,
      textDecoration: 'underline',
      wordBreak: 'break-word',
    }),
    uploadFileSize: css({
      color: '#7D828B',
      fontSize: '12px',
      fontWeight: 600,
    }),
    uploadButton: css({
      borderRadius: '24px',
      border: '1px solid #52AE30',
      background: '#fff',
      padding: '12px 32px 12px 24px',
      marginTop: '18px',
      fontWeight: 400,
    }),
    trashCanIcon: css({
      cursor: 'pointer',
      marginLeft: '18px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '8px',
      },
    }),
    errorMessage: css({
      fontSize: '12px',
      color: '#FF435A',
      margin: '10px 0px',
      lineHeight: '16px',
    }),
    divider: css({
      borderTopWidth: '1px',
      margin: '14px 0px',
    }),
  }));

export default useStyles;
