import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography, Box, Grid, Button } from '@mui/material';
import useStyles from './ocr-data-preview';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../../providers/application/application.provider';
import {
  checkOcrAttemptsCountAsync,
  confirmOcrDataAsync,
} from '../../../providers/application/application.actions';
import { ReactComponent as WarningAmber } from '../../../assets/images/warning_amber.svg';
import { ReactComponent as SearchUser } from '../../../assets/images/search-user.svg';

const OcrDataPreview = () => {
  const {
    ocrCompanyName,
    ocrCombinedAddress,
    ocrCompanyActivity,
    ocrOwnerName,
    ocrOwnerId,
    ocrCompanyCapital,
    ocrSigned,
    mainDataInconsistency,
  } = useApplicationState();
  const dispatch = useApplicationDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const ocrFields = [
    {
      label: t('ocr-details-company-name-label'),
      name: 'companyName',
      value: ocrCompanyName,
    },
    {
      label: t('ocr-details-combined-address-label'),
      name: 'combinedAddress',
      value: ocrCombinedAddress,
    },
    {
      label: t('ocr-details-company-activity-label'),
      name: 'companyActivity',
      value: ocrCompanyActivity,
    },
    {
      label: t('ocr-details-owner-name-label'),
      name: 'ownerName',
      value: ocrOwnerName,
    },
    {
      label: t('ocr-details-owner-id-label'),
      name: 'ownerId',
      value: ocrOwnerId,
    },
    {
      label: t('ocr-details-company-capital-label'),
      name: 'companyCapital',
      value: ocrCompanyCapital,
    },
    {
      label: t('ocr-details-signed-label'),
      name: 'signed',
      value: ocrSigned,
    },
  ];

  const hasEmptyOcrFields = ocrFields.some((item) => item.value === null || item.value === false);

  const getProcessedValue = (value) => {
    if (value === null) {
      return <span style={{ color: '#FF435A' }}>{t('ocr-details-missing-label')}</span>;
    }

    if (typeof value === 'boolean') {
      if (value) {
        return <span style={{ color: '#52AE30' }}>{t('general.yes')}</span>;
      }
      return <span style={{ color: '#FF435A' }}>{t('general.no')}</span>;
    }

    return <span style={{ color: '#7D828B' }}>{value}</span>;
  };

  const confirmOcrDetails = () => {
    confirmOcrDataAsync(dispatch);
  };

  const checkOcrAttemptsCount = () => {
    checkOcrAttemptsCountAsync(dispatch);
  };

  return (
    <div>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '12px',
          }}
        >
          <SearchUser />
        </div>

        <Box className={classes.ocrPreviewHeader}>{t('ocr-details-header')}</Box>
        <Box className={classes.ocrPreviewSubheader}>{t('ocr-details-subheader')}</Box>
      </Grid>
      <Grid item xs={12} className={classes.contentOuterContainer}>
        <div className={classes.contentInnerContainer}>
          {ocrFields.map((item, i) => (
            <div key={item.name} style={{}}>
              <Typography style={{ fontWeight: 700 }}>{item.label}</Typography>
              <Typography>{getProcessedValue(item.value)}</Typography>
              {i !== ocrFields.length - 1 && (
                <Divider
                  orientation='vertical'
                  style={{ borderTopWidth: '1px', margin: '14px 0px' }}
                />
              )}
            </div>
          ))}
        </div>
      </Grid>
      {(hasEmptyOcrFields || mainDataInconsistency) && (
        <Grid item xs={12}>
          <Box className={classes.ocrPreviewErrorMessage}>
            <div>
              <WarningAmber />
            </div>
            <Typography style={{ color: '#FF435A', fontSize: '12px', marginLeft: '8px' }}>
              {mainDataInconsistency
                ? t('ocr-details-invalidate-field-message')
                : t('ocr-details-missing-field-message')}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className={classes.buttonWrapper}>
          <Button
            id='btnForward_ConfirmOcrData_CapitalAcc'
            variant='contained'
            color='primary'
            disabled={hasEmptyOcrFields}
            onClick={confirmOcrDetails}
            className={classes.buttonConfirm}
          >
            {t('ocr-details-confirm')}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.buttonWrapper}>
          <Button
            id='btn_UploadAgainOcr_CapitalAcc'
            variant='contained'
            onClick={checkOcrAttemptsCount}
            className={classes.buttonUploadAgain}
          >
            {t('ocr-details-upload-again')}
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default OcrDataPreview;
