import React from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from './entry-step-buttons.styles';
import { ReactComponent as ArrowRight } from '../../../assets/images/arrow-right.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/images/otp-money.svg';
import { ReactComponent as Receipt } from '../../../assets/images/otp-receipt.svg';
import { ReactComponent as JobSearch } from '../../../assets/images/otp-job-search.svg';
import { useApplicationState } from '../../../providers/application/application.provider';

const EntryStepButtons = ({ handleButtonClick }) => {
  const classes = useStyles();
  const {
    nomenclatures: {
      processes,
      onlinePaymentAccountProcessId,
      assistedPaymentAccountProcessId,
      capitalAccountProcessId,
    },
  } = useApplicationState();

  const iconMapper = {
    [onlinePaymentAccountProcessId]: <MoneyIcon />,
    [assistedPaymentAccountProcessId]: <Receipt />,
    [capitalAccountProcessId]: <JobSearch />,
  };

  return (
    <Grid item xs={12} className={classes.buttonContainer}>
      {processes.map((process) => (
        <button
          key={process.id}
          type='button'
          className={classes.button}
          onClick={() => handleButtonClick(process.id)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              className={`${classes.iconContainer} ${process.note && classes.secondaryBackground}`}
            >
              {iconMapper[process.id]}
            </div>
            <div style={{ textAlign: 'left' }}>
              <Typography className={classes.labelInfo}>{process.name}</Typography>
              {process.note && (
                <Typography className={classes.officeNote}>{process.note}</Typography>
              )}
            </div>
          </div>
          <ArrowRight className={process.note && classes.arrowRightSecondaryColor} />
        </button>
      ))}
    </Grid>
  );
};

export default EntryStepButtons;
