import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Rating,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Fab from '@mui/material/Fab';
import { ReactComponent as Smile } from '../../assets/images/smile-icon.svg';
import useStyles from './rating-dialog.styles';
import { saveRatingDataAsync, setRateScore } from '../../providers/application/application.actions';
import {
  useApplicationDispatch,
  useApplicationState,
} from '../../providers/application/application.provider';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

const RatingDialog = ({ open, handleClose }) => {
  const { ratingSurvey, activeStep, isFreeLancer } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const showThanksMessage = ratingSurvey.every((el) => el.rateScore !== null);

  const classes = useStyles(showThanksMessage);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const handleRate = (e) => {
    dispatch(setRateScore({ name: e.target.name, rateScore: Number(e.target.value) }));

    saveRatingDataAsync(
      {
        [e.target.name]: Number(e.target.value),
      },
      dispatch,
    );
  };

  useEffect(() => {
    if (showThanksMessage) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [showThanksMessage]);

  useEffect(() => {
    // Note: Triggers page change for hotjar heatmaps
    if (typeof window !== 'undefined' && typeof window.hj === 'function') {
      let pageName;

      if (open) {
        pageName = '/NPS';
      } else {
        pageName = `/${activeStep}`;

        if (isFreeLancer) {
          pageName += '_FreeLancer';
        }
      }

      if (pageName) {
        window.hj('stateChange', pageName);
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      className={classes.infoDialog}
    >
      <DialogContent className={classes.dialogContent}>
        <Fab size='medium' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </Fab>
        {showThanksMessage && (
          <Grid item xs={12} className={classes.thanksMessage}>
            <Typography>{t('request-success.rating-success-message')}</Typography>
            <div style={{ marginTop: '36px' }}>
              <Smile />
            </div>
          </Grid>
        )}
        <Grid container className={classes.gridContent}>
          {ratingSurvey.map((rt) => (
            <Grid item xs={12} sm={12} key={rt.name} className={classes.ratingSection}>
              <Grid item xs={12} className={classes.title}>
                {rt.title}
              </Grid>
              <Grid item xs={12} className={classes.question}>
                {rt.question}
              </Grid>
              <Rating
                className={classes.ratingIcons}
                name={rt.name}
                value={rt.rateScore}
                onChange={(e) => handleRate(e)}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RatingDialog;
