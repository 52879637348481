import { css } from '@emotion/css';
import { makeStyles } from '../../../utils/common.utils';

const useStyles = () =>
  makeStyles((theme) => ({
    ocrPreviewHeader: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      fontWeight: 900,
      fontSize: '28px',
      lineHeight: '32px',
      color: '#151617',
      marginBottom: '12px',
    }),
    ocrPreviewSubheader: css({
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '24px',
      whiteSpace: 'pre-line',
      color: '#7D828B',
      lineHeight: '24px',
    }),
    contentOuterContainer: css({
      padding: '12px',
      borderRadius: '32px',
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px 0px rgba(73, 92, 136, 0.15)',
    }),
    contentInnerContainer: css({
      backgroundColor: '#F6F7FA',
      padding: '16px 24px',
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }),
    ocrPreviewErrorMessage: css({
      display: 'flex',
      alignItems: 'center',
      borderRadius: '16px',
      marginTop: '24px',
      padding: '16px',
      backgroundColor: '#FFEBED',
    }),
    buttonWrapper: css({
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    buttonConfirm: css({
      width: '205px',
      marginTop: '24px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }),
    buttonUploadAgain: css({
      marginTop: '16px',
      width: '205px',
      color: '#F60',
      backgroundColor: '#fff',
      border: '1px solid #F60',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }),
  }));

export default useStyles;
