import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { completeIdentificationAsync } from '../../providers/application/application.actions';
import { useApplicationDispatch } from '../../providers/application/application.provider';

const CompleteIdentification = () => {
  const navigate = useNavigate();
  const dispatch = useApplicationDispatch();
  const query = new URLSearchParams(useLocation().search);
  const requestNumber = query.get('requestNumber');
  const securityCode = query.get('sc');
  const isSuccessful = query.get('isSuccessful') === 'true';

  useEffect(() => {
    completeIdentificationAsync({ requestNumber, securityCode, isSuccessful }, dispatch, () => {
      navigate('/Request');
    });
  }, []);

  return <div />;
};

export default CompleteIdentification;
